import { useTranslations } from 'next-intl';
import styled from '@emotion/styled';
import { OpeningTimesSchedule } from '@silvertours/front-entities';

const Wrapper = styled.section`
  width: 100%;
  max-width: 222px;
  margin-top: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  font-family: ${({ theme }) => theme.fontFamily.default};
`;

export const Schedule = () => {
  const t = useTranslations('features.navigationLegacy.footer');

  return (
    <Wrapper aria-labelledby="opening-times-title">
      <p id="opening-times-title">{t('openingTimes')}</p>
      <OpeningTimesSchedule />
    </Wrapper>
  );
};
