import { analytics, PromotionBanner } from '@silvertours/front-entities';
import { Ui } from '@silvertours/front-shared';
import { useTranslations } from 'next-intl';

import { ButtonContainer } from './AppInstallBanner.styles';

export const AppInstallBanner = ({
  isShowing,
  onClose,
}: {
  isShowing: boolean;
  onClose: () => void;
}) => {
  const t = useTranslations('features.mobileAppPromotion');

  return (
    <Ui.ForegroundLayer
      id="app-install-banner"
      isShowing={isShowing}
      onClose={onClose}
    >
      <PromotionBanner title={t('title')}>
        <ButtonContainer>
          <Ui.PlayStoreLink
            id="app-install-banner-play-store-anchor"
            href={t('playStoreLink')}
            onClick={analytics.gtm.trackAppPromoBannerAndroidAppLinkClicked}
          />
          <Ui.AppStoreLink
            id="app-install-banner-app-store-anchor"
            href={t('appStoreLink')}
            onClick={analytics.gtm.trackAppPromoBannerIosAppLinkClicked}
          />
        </ButtonContainer>
      </PromotionBanner>
    </Ui.ForegroundLayer>
  );
};
