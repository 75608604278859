import type { LogoProps } from '../types';
import { Svg } from '../ThirdPartyLogo.styles';

const viewBox = {
  both: '0 0 137 49',
  horizontal: '0 15.2 137 28',
  vertical: '9 0 119 49',
  none: '9 15.2 119 28',
};

const BudgetLogo = ({ withPadding = 'both', ...props }: LogoProps) => (
  <Svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox[withPadding]}
    role="img"
    aria-hidden="true"
  >
    <title>Budget</title>
    <path fill="#f78b00" d="M24.08 34.72h12.15V15.5L24.08 34.72Z" />
    <path fill="#ed5505" d="M9 15.19v19.53l27.23-19.53H9Z" />
    <path
      fill="#00285f"
      d="M125.8 15.95c1.07 0 1.95.94 1.95 2.12 0 1.17-.88 2.1-1.96 2.1s-1.96-.93-1.96-2.1c0-1.18.88-2.12 1.96-2.12Zm0 3.9c.9 0 1.64-.8 1.64-1.78 0-.99-.74-1.8-1.65-1.8-.9 0-1.65.81-1.65 1.8 0 .98.74 1.79 1.65 1.79Zm-.77-2.98h.77c.41 0 .82.18.82.7 0 .34-.2.56-.5.64l.58 1.04h-.48l-.51-1h-.29v1h-.4v-2.38Zm.71 1.04c.22 0 .48-.07.48-.35 0-.26-.23-.33-.44-.33h-.36v.68h.32Zm-4.72 14.42c-1.54 0-2.26-.81-2.26-2.57v-6.72h2.96v-2.91h-2.96v-4.17h-3.03v13.8c0 3.5 1.84 5.59 4.81 5.59.62 0 1.23-.1 1.23-.1v-2.96c-.38.04-.6.04-.75.04ZM112.3 21.9a6.22 6.22 0 0 0-4.78-2.11c-3.84 0-6.8 3.46-6.8 7.8 0 4.4 2.9 7.76 6.87 7.76a7 7 0 0 0 5.5-2.98l-2.36-1.8a4 4 0 0 1-3.14 1.77c-1.62 0-3.27-1.26-3.67-3.42h10.16s.93-3.93-1.78-7.02Zm-8.36 4.28c.11-.9 1.1-3.39 3.58-3.39 2.4 0 3.46 2.42 3.55 3.39h-7.13Zm-7.78-6.05v1.36a5.58 5.58 0 0 0-4-1.7c-3.88 0-6.93 3.36-6.93 7.76 0 4.42 3.05 7.8 6.93 7.8 1.41 0 2.84-.56 3.93-1.63 0 0 .61 5.69-4 5.69-1.96 0-3.33-1.52-3.95-2.67l-2.42 1.85a7.1 7.1 0 0 0 6.36 3.9 6.9 6.9 0 0 0 4.79-1.74c1.46-1.4 2.27-3.36 2.27-5.99V20.13h-2.98Zm-4 12.18c-2.19 0-3.9-2.11-3.9-4.79 0-2.6 1.75-4.7 3.9-4.7 2.13 0 3.93 2.15 3.93 4.7 0 2.63-1.77 4.8-3.93 4.8Zm-15.57 3.04c-3.88 0-6.93-3.37-6.93-7.77 0-4.41 3.05-7.8 6.93-7.8a5.6 5.6 0 0 1 3.93 1.64v-6.23h3.03v19.85H80.6v-1.4a5.58 5.58 0 0 1-4.01 1.7Zm0-12.51c-2.18 0-3.9 2.1-3.9 4.77 0 2.6 1.75 4.7 3.9 4.7 2.13 0 3.93-2.15 3.93-4.7 0-2.63-1.77-4.77-3.93-4.77Zm-8.43 4.98v-7.7h-3.03v7.7c0 2.73-1.3 4.5-3.31 4.5s-3.3-1.77-3.3-4.5v-7.7h-3.04v7.7c0 4.59 2.82 7.53 6.34 7.53 3.53 0 6.34-2.94 6.34-7.53Zm-26.92 7.42V15.19h6.4c2.5 0 5.51 1.42 5.51 5.2 0 2.24-.97 3.69-1.84 4.23 2.35.98 2.8 3.4 2.8 4.83 0 3.2-2.42 5.79-6.3 5.79h-6.57Zm6.22-3c.8 0 1.42-.11 1.9-.32a2.8 2.8 0 0 0 1.61-2.66c0-1.23-.64-2.17-1.6-2.62a4.69 4.69 0 0 0-2.15-.35h-2.9v5.94h3.14Zm-.5-8.81c.56 0 1.46-.04 1.99-.32.8-.47 1.05-1.22 1.05-2.35 0-1.22-.67-1.94-1.24-2.23-.44-.24-1-.34-1.87-.34h-2.56v5.23h2.63Z"
    />
  </Svg>
);

// eslint-disable-next-line import/no-default-export
export default BudgetLogo;
