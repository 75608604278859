import isEqual from 'lodash/isEqual';
import { Session } from '@silvertours/front-shared';
import { SearchFormState } from '../context/types';
import { SearchHistoryItem, SearchHistoryType } from './types';

export const SEARCH_HISTORY_SESSION_STORAGE_KEY = 'SearchHistoryKey8934087340';
export const SEARCH_HISTORY_LIMIT = 6;

export const getSearchHistory = (): SearchHistoryType => {
  const data = Session.SessionStorageManager.getData(
    SEARCH_HISTORY_SESSION_STORAGE_KEY,
  );

  return { items: data || [] };
};

export const addItemToSearchHistory = (item: SearchHistoryItem) => {
  const history: SearchHistoryType = getSearchHistory();
  if (!history.items.find(storedItem => isEqual(storedItem, item))) {
    history.items.unshift(item);
    if (history.items.length > SEARCH_HISTORY_LIMIT) {
      history.items.pop();
    }
    Session.SessionStorageManager.setData(
      SEARCH_HISTORY_SESSION_STORAGE_KEY,
      history.items,
    );
  }
};

export const clearSearchHistory = () => {
  Session.SessionStorageManager.removeData(SEARCH_HISTORY_SESSION_STORAGE_KEY);
};

export const mapFormToSearchItem = (formBody: SearchFormState) => ({
  depAirportCode:
    formBody.depLocation?.iataCode || formBody.depLocation?.cityCode || '',
  destAirportCode:
    formBody.destLocation?.iataCode || formBody.destLocation?.cityCode || '',
  destName:
    formBody.destLocation?.cityName || formBody.destLocation?.name || '',
  depName: formBody.depLocation?.cityName || formBody.depLocation?.name || '',
  depDate: formBody.depDate.split('T')[0],
  depTime: formBody.depDate.split('T')[1],
  destDate: formBody.destDate.split('T')[0],
  destTime: formBody.destDate.split('T')[1],
  depWeekDay: '',
  destWeekDay: '',
  imageUrl: '',
});
