import { useState } from 'react';
import { String } from '@silvertours/front-shared';
import AirConditioning from '@engineering/icons/air-conditioning';
import Automatic from '@engineering/icons/automatic';
import CityStation from '@engineering/icons/city-station';
import Airport from '@engineering/icons/airport';
import Doors from '@engineering/icons/doors';
import Seats from '@engineering/icons/seats';
import Transmission from '@engineering/icons/transmission';
import { useLocale, useCarImage } from '../Runtime';

import {
  Wrapper,
  Sample,
  Image,
  Caption,
  CarAttributes,
  CarAttribute,
  CarAttributeEmpty,
  StyledIcon,
  Stations,
  CarCategory,
  Price,
  PriceValue,
  PriceSuffix,
  CallToAction,
  StyledArrowNext,
} from './SerpOffer.styles';

type SerpOfferProps = {
  image: string;
  transmission: string;
  price: number;
  currency: string;
  carCategory: string;
  carName: string;
  seats: number;
  doors: string;
  airConditioning: boolean;
  city: string;
  isAirport: boolean;
  onClick?: () => void;
  translations: {
    airConditioning: string;
    transmissionManual: string;
    transmissionAutomatic: string;
    pricePrefix: string;
    priceSuffix: string;
    searchButtonText: string;
  };
};

const SerpOffer = ({
  image,
  transmission,
  price,
  currency,
  carCategory,
  carName,
  seats,
  doors,
  airConditioning,
  city,
  isAirport,
  onClick,
  translations,
}: SerpOfferProps) => {
  const { language } = useLocale();
  const [loading, setLoading] = useState(false);
  const imageUrl = useCarImage(image);

  const handleClick = () => {
    if (onClick && !loading) {
      setLoading(true);
      onClick();
    }
  };

  const attributes = [
    { label: 'seats', value: seats, icon: Seats },
    { label: 'doors', value: doors, icon: Doors },
    {
      label: 'transmission',
      value:
        transmission === 'manual'
          ? translations.transmissionManual
          : translations.transmissionAutomatic,
      icon: transmission === 'manual' ? Transmission : Automatic,
    },
    ...(airConditioning
      ? [
          {
            label: 'airConditioning',
            icon: AirConditioning,
            value: translations.airConditioning,
          },
        ]
      : []),
  ];

  return (
    <Wrapper onClick={handleClick} hasAction={!!onClick}>
      <CarCategory>{carCategory}</CarCategory>
      <Price value={price}>
        {translations.pricePrefix}{' '}
        <PriceValue>{String.formatPrice(price, currency, language)}</PriceValue>
        <PriceSuffix>{translations.priceSuffix}</PriceSuffix>
      </Price>
      <Sample>
        <Image src={imageUrl} alt={carName} fill />
      </Sample>
      <Caption aria-hidden="true">{carName}</Caption>
      <CarAttributes>
        {carCategory !== 'Transporter' &&
          attributes.map(({ label, value, icon: Icon }) => (
            <CarAttribute key={label}>
              <StyledIcon as={Icon} />
              <data value={value}>{value}</data>
            </CarAttribute>
          ))}
        {carCategory === 'Transporter' && <CarAttributeEmpty />}
      </CarAttributes>
      <Stations>
        <StyledIcon as={isAirport ? Airport : CityStation} />
        <span>{city}</span>
      </Stations>
      <CallToAction
        icon={<StyledArrowNext />}
        size="small"
        loading={loading}
        title={translations.searchButtonText}
      />
    </Wrapper>
  );
};

export { SerpOffer };
