import React from 'react';

import { HostLegacy, LayoutLegacy } from '@silvertours/front-legacy-entities';
import { MediaLegacy } from '@silvertours/front-legacy-shared';
import { StageContentContainer, StageCaption } from './Stage.styles';
import { NewsletterRegistration } from '../NewsletterRegistrationLegacy';

const NewsletterStage = () => {
  const { formatImage } = HostLegacy.useHost();
  const image: MediaLegacy.ImageProps = {
    src: formatImage('/static/bm/global/files/images/XL-M_1400x470_200kb.jpg'),
    alt: 'Newsletter',
    id: 'newsletter-stage',
  };

  return (
    <LayoutLegacy.ImageSegment image={image}>
      <StageContentContainer>
        <StageCaption>
          <NewsletterRegistration type="landing" />
        </StageCaption>
      </StageContentContainer>
    </LayoutLegacy.ImageSegment>
  );
};

export { NewsletterStage };
