import { useTranslations } from 'next-intl';
import {
  ContentLayout,
  GeneralPageContent,
} from '@silvertours/common-landingpages-view';
import {
  BenefitsLegacy,
  ContentArticle,
  OffersModule,
  SearchFormLegacy,
  SerpOffersModule,
  StageLegacy,
} from '@silvertours/front-features';
import { AcrissFilter } from '@silvertours/back-domain-offer';
import { Body } from '../body';

const GeneralContent = ({
  content: {
    article,
    attributes: { island, airport, railStation, category, bookingAttribute },
    globalRating,
    introduction,
    links,
    location,
    offersByCategory,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<GeneralPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);
  const offersTranslations = useTranslations('features.offers');
  const { makeSearchHandler } = SearchFormLegacy.useSubmitInitialSearch();
  let destinationTranslation = offersTranslations('serp.title.destination');
  let defaultTitle = offersTranslations('serp.title.default');
  let searchQuery = '';

  if (island) {
    destinationTranslation = offersTranslations('serp.title.island');
  }

  if (category) {
    if (category.isSportscar) {
      searchQuery = new URLSearchParams({
        car_type: 'sportscar',
      }).toString();
    } else {
      let params = {};
      const filter = new AcrissFilter(category?.query);
      const filterParams = filter.getQueryParam();
      if (filterParams.length > 0) {
        filterParams.forEach(param => {
          params = { ...params, ...param };
          searchQuery = new URLSearchParams(params).toString();
        });
      }
    }
    destinationTranslation = offersTranslations(
      'serp.title.categoryDestination',
    ).replace('%CATEGORY%', category.name);
    defaultTitle = offersTranslations('serp.title.category').replace(
      '%CATEGORY%',
      category.name,
    );
  }

  if (bookingAttribute) {
    let params = {};
    bookingAttribute.forEach(attribute => {
      params = { ...params, ...attribute.query };
      searchQuery = new URLSearchParams(params).toString();
      destinationTranslation = offersTranslations(
        'serp.title.categoryDestination',
      ).replace('%CATEGORY%', attribute.name);
      defaultTitle = offersTranslations('serp.title.category').replace(
        '%CATEGORY%',
        attribute.name,
      );
    });
  }

  if (airport) {
    destinationTranslation = offersTranslations('serp.title.airport');
    searchQuery = new URLSearchParams({
      station_types: 'airport',
    }).toString();
  }

  if (railStation) {
    destinationTranslation = offersTranslations('serp.title.rail');
    searchQuery = new URLSearchParams({
      station_types: 'rail',
    }).toString();
  }

  return (
    <Body
      title={primaryHeadline}
      stage={
        <StageLegacy.Stage content={stage.content} headline={primaryHeadline} />
      }
      content={({ container, containerModule, contentModule }) => [
        containerModule(
          'usp',
          <BenefitsLegacy.Usp rating={globalRating.content} />,
        ),
        containerModule(
          'introduction-content',
          <ContentArticle
            modules={introduction?.segments ?? []}
            translations={translations}
          />,
          !!introduction?.segments,
        ),
        contentModule(
          'serpOffers',
          !!offersByCategory.content && (
            <SerpOffersModule
              content={offersByCategory.content}
              translations={{
                airConditioning: offersTranslations('car.airConditioning'),
                carTypes: t.raw('offers.car.type' as any),
                prevText: offersTranslations('prevText'),
                nextText: offersTranslations('nextText'),
                searchButtonText: offersTranslations('searchButtonText'),
                disclaimertext: offersTranslations('disclaimerSerpOffers'),
                pricePrefix: offersTranslations('pricePrefix'),
                priceSuffix: offersTranslations('priceSuffix'),
                serpTitle: location.name
                  ? destinationTranslation.replace('%LOCATION%', location.name)
                  : defaultTitle,
                transmissionAutomatic: offersTranslations(
                  'car.transmissionAutomatic',
                ),
                transmissionManual: offersTranslations(
                  'car.transmissionManual',
                ),
              }}
              searchQuery={searchQuery}
              isAirport={!!airport}
            />
          ),
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        container('intro', [
          contentModule(
            'offers',
            !!offersByCategory.content && (
              <OffersModule
                content={offersByCategory.content}
                onClick={makeSearchHandler(stage.content.search?.location)}
                translations={{
                  badgeText: offersTranslations('badge'),
                  disclaimer: offersTranslations('disclaimerLocation'),
                  disclaimerOverlayCTA: offersTranslations(
                    'disclaimerOverlayCTA',
                  ),
                  durationFrom: offersTranslations('durationFrom'),
                  headline: location.name
                    ? offersTranslations('headline.destination').replace(
                        '%LOCATION%',
                        location.name,
                      )
                    : offersTranslations('headline.default'),
                  pricePrefix: offersTranslations('pricePrefix'),
                  priceSuffix: offersTranslations('priceSuffix'),
                  searchButtonText: offersTranslations('searchButtonText'),
                  offerDetailsTitle: offersTranslations('offerDetailsTitle'),
                  carTypes: t.raw('offers.car.type' as any),
                }}
              />
            ),
            !!offersByCategory.content &&
              Object.keys(offersByCategory.content).length > 0,
          ),
        ]),
        containerModule(
          'article',
          <ContentArticle
            modules={article.segments}
            translations={translations}
            searchQuery={searchQuery}
          />,
        ),
        containerModule(
          'linkLists',
          <ContentArticle
            modules={links.content ?? []}
            translations={translations}
          />,
        ),
      ]}
      {...commonProps}
    />
  );
};
export { GeneralContent };
