import React, { useEffect, useRef } from 'react';

import {
  ContentLayout,
  ErrorPageContent,
} from '@silvertours/common-landingpages-view';
import { BenefitsLegacy, StageLegacy } from '@silvertours/front-features';
import { analytics } from '@silvertours/front-entities';

import { Body } from './body';

const ErrorContent = ({
  content: {
    globalRating,
    statusCode,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<ErrorPageContent>) => {
  const tracked = useRef(false);
  useEffect(() => {
    if (!tracked.current) {
      const label = statusCode?.toString() ?? '';
      analytics.gtm.trackErrorPageView(label);
      tracked.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Body
      title={primaryHeadline}
      stage={
        <StageLegacy.Stage
          statusCode={statusCode}
          content={stage.content}
          headline={primaryHeadline}
        />
      }
      content={({ containerModule }) => [
        containerModule(
          'usp',
          <BenefitsLegacy.Usp rating={globalRating.content} />,
        ),
      ]}
      {...commonProps}
    />
  );
};

export { ErrorContent };
