import { ContentModuleStyle } from '@silvertours/common-landingpages-view';

export type BackgroundStyle =
  | 'default'
  | 'gray'
  | 'green'
  | 'mint'
  | 'orange'
  | 'brand'
  | 'mintAlt';

export type HasBackgroundStyle = {
  backgroundStyle?: BackgroundStyle;
};

export const selectBackgroundStyle = (style: ContentModuleStyle) => style;
