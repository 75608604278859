import type { LogoProps } from '../types';
import { Svg } from '../ThirdPartyLogo.styles';

const viewBox = {
  both: '0 0 137 49',
  horizontal: '0 8.7 137 32',
  vertical: '28 0 82 49',
  none: '28 8.7 82 32',
};

const HertzLogo = ({ withPadding = 'both', ...props }: LogoProps) => (
  <Svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox[withPadding]}
    role="img"
    aria-hidden="true"
  >
    <title>Hertz</title>
    <path fill="#facc21" d="M103.69 40.14H28l.87-5.03h75.69l-.87 5.03Z" />
    <path
      fill="#1a1919"
      d="M85.46 27.44c-.68-.77-.27-2.31.07-4.05l1.19-5.91h4.85l.88-4.4H87.6l.86-4.4h-4.57l-3 15.11c-.46 2.26-.63 4.02.3 5.92.85 1.77 3.77 3.46 7.47 2.4l.82-4.09c-2.5.33-3.52-.02-4.01-.58h-.01ZM46.69 8.69l-2.19 10.9h-8.03l2.18-10.9h-4.62L29.36 32.1h4.61L35.6 24h8.04L42 32.1h4.58l4.68-23.4H46.7l-.01-.01Zm26.38 6.04c-1.6 1.43-2.51 3.3-3.1 6.23L67.74 32.1h4.58l1.85-9.23c.44-2.17.77-3.52 1.53-4.35 1.05-1.14 2.57-1.2 4.4-1.04l.88-4.4a8.37 8.37 0 0 0-7.91 1.65Zm35.76-1.65h-14.3l-.88 4.4h8.3l-10.38 10.4-.84 4.22h14.3l.88-4.4h-8.34L108 17.27l.83-4.18v-.01Zm-45.29 6.91H56.2c.26-1.05 1.82-3.64 4.71-3.36 3.12.3 2.63 3.36 2.63 3.36Zm-2.98-7.26c-1.54 0-3.66.5-5.74 2.25-4.69 3.91-7.02 17.47 3.05 17.47 2.61 0 5.52-.88 7.53-2.5l-1.82-3.27c-1.52 1.24-9.2 3.96-8.4-2.66h12c.01-.02 1.54-5.31-.66-8.51-1.88-2.73-5.33-2.78-5.96-2.78Z"
    />
    <path
      fill="#8a8b8a"
      d="M108.13 29.66c.61 0 1.02.46 1.02 1.06l-.03.28c-.15.74-.83 1.37-1.55 1.37-.62 0-1.03-.47-1.03-1.07l.03-.28c.15-.74.83-1.36 1.55-1.36h.01Zm.82 1.31c.02-.09.02-.17.02-.25-.01-.51-.31-.86-.86-.88-.62.01-1.24.57-1.36 1.22-.03.08-.03.16-.03.24.01.52.32.87.85.88.62 0 1.25-.57 1.38-1.21Zm-.93-.61c.3 0 .45.16.4.44a.54.54 0 0 1-.34.4l.2.46h-.32l-.17-.42h-.22l-.08.42h-.27l.26-1.3h.54Zm.12.44v.01c.02-.13-.04-.19-.18-.19h-.26l-.07.38h.26c.14 0 .23-.08.25-.2Z"
    />
  </Svg>
);

// eslint-disable-next-line import/no-default-export
export default HertzLogo;
