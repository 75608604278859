import { useEffect, useState } from 'react';

type MobileOS = 'ios' | 'android' | 'unknown';

export const useMobileOS = (): MobileOS => {
  const [os, setOS] = useState<MobileOS>('unknown');

  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(ua)) {
      setOS('ios');
    } else if (/android/.test(ua)) {
      setOS('android');
    }
  }, []);

  return os;
};
