import dynamic from 'next/dynamic';
import type { FC, HTMLAttributes } from 'react';
import type { LogoProps } from './types';
import { LogoWrapper } from './ThirdPartyLogo.styles';
import { logoMap } from './map';
import AvisLogo from './assets/Avis';
import BudgetLogo from './assets/Budget';
import EnterpriseLogo from './assets/Enterprise';
import EuropcarLogo from './assets/Europcar';
import HertzLogo from './assets/Hertz';

type Props = {
  name: string;
  grayScale?: boolean;
} & Pick<LogoProps, 'withPadding'> &
  Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

/**
 * Dynamically loads a third party logo.
 * By default the logo is positioned in a view box of 137x49.
 * This can be adjusted using the `withPadding` prop.
 */
export const ThirdPartyLogo = ({
  name,
  withPadding = 'both',
  grayScale = false,
  className,
}: Props) => {
  const logoComponent = logoMap[name.toLowerCase()];
  if (!logoComponent) {
    return null;
  }

  // import logos used above the fold
  if (
    logoComponent === 'Avis' ||
    logoComponent === 'Budget' ||
    logoComponent === 'Enterprise' ||
    logoComponent === 'Europcar' ||
    logoComponent === 'Hertz'
  ) {
    return (
      <LogoWrapper grayScale={grayScale} className={className}>
        {logoComponent === 'Avis' && <AvisLogo withPadding={withPadding} />}
        {logoComponent === 'Budget' && <BudgetLogo withPadding={withPadding} />}
        {logoComponent === 'Enterprise' && (
          <EnterpriseLogo withPadding={withPadding} />
        )}
        {logoComponent === 'Europcar' && (
          <EuropcarLogo withPadding={withPadding} />
        )}
        {logoComponent === 'Hertz' && <HertzLogo withPadding={withPadding} />}
      </LogoWrapper>
    );
  }

  const LogoComponent = dynamic(async () => {
    try {
      const module = await import(`./assets/${logoComponent}`);
      return module.default;
    } catch {
      return null;
    }
  }) as FC<LogoProps> | null;

  if (!LogoComponent) {
    return null;
  }

  return (
    <LogoWrapper grayScale={grayScale} className={className}>
      <LogoComponent withPadding={withPadding} />
    </LogoWrapper>
  );
};
