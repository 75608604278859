import styled from '@emotion/styled';
import { css, SerializedStyles, Theme } from '@emotion/react';

import { ContentModuleStyle } from '@silvertours/common-landingpages-view';
import { Global } from '../../styles';

const getBackground = (
  theme: Theme,
  style: ContentModuleStyle,
): SerializedStyles | string =>
  ({
    default: ';',
    gray: css`
      background-color: ${theme.color.grey05};
      padding-top: 6rem;
      padding-bottom: 6rem;
    `,
    green: css`
      background-image: ${theme.linearGradient(theme.gradient.green)};
    `,
    mint: css`
      background-image: ${theme.linearGradient(theme.gradient.mint)};
    `,
    mintAlt: css`
      background-image: ${theme.linearGradient(
        theme.gradient.mintAlt,
        '90deg',
      )};
    `,
    orange: css`
      background-image: ${theme.linearGradient(theme.gradient.orange)};
    `,
    brand: css`
      background-image: ${theme.linearGradient(theme.gradient.brand, '135deg')};
    `,
  })[style];

const WaveSvg = styled.svg`
  width: 100%;
  height: auto;
  fill: ${({ theme }) => theme.color.white};
`;

const FixedWave = styled.div`
  position: relative;
`;

const FixedWaveContent = styled.div`
  position: relative;
  z-index: 10;
`;

const FixedWaveWaves = styled.div<{
  height?: number;
  isFullWidth?: boolean;
  backgroundStyle?: ContentModuleStyle;
}>`
  ${Global.layoutContainerContent};

  ${({ theme, backgroundStyle }) =>
    backgroundStyle && getBackground(theme, backgroundStyle)};

  position: absolute;
  top: 0;
  left: ${({ isFullWidth }) => (isFullWidth ? 'calc(-50vw + 50%)' : '0')};
  right: ${({ isFullWidth }) => (isFullWidth ? 'calc(-50vw + 50%)' : '')};
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ isFullWidth }) => (isFullWidth ? '' : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '')};

  ${({ theme }) => theme.mq('xxl')} {
    left: ${({ isFullWidth }) => (isFullWidth ? 'calc(-960px + 50%)' : '0')};
    right: ${({ isFullWidth }) => (isFullWidth ? 'calc(-960px + 50%)' : '')};
  }

  /* Fix for sub-pixel hairline showing on some systems due to scaled SVG. */
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.white};
  }
`;

const FixedWaveEnd = styled.div`
  & > svg {
    margin-bottom: -6px;
  }
`;

const ScalableWaveSvg = css`
  & > svg {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    width: 100vw;
    margin-top: -1px;
  }
`;

/** Get spacing for scalable waves, with `scalable6` being a special ”unicorn“. */
const getSpacing = (
  waveType: string,
  regularValue: string,
  specialValue: string,
) => (waveType === 'scalable6' ? specialValue : regularValue);

const ScalableWave = styled.div<{
  waveType: string;
  isFullWidth?: boolean;
  backgroundStyle?: ContentModuleStyle;
}>`
  ${Global.layoutContainerContent};

  ${({ theme, backgroundStyle }) =>
    backgroundStyle && getBackground(theme, backgroundStyle)};

  ${({ backgroundStyle, theme, waveType, isFullWidth }) => {
    if (!isFullWidth) return '';

    return css`
      margin-top: ${getSpacing(waveType, '-3rem', '-1.25rem')};
      overflow: hidden;

      ${theme.mq('md')} {
        margin-top: ${getSpacing(waveType, '-6rem', '-1.25rem')};
        ${['mint', 'mintAlt'].includes(backgroundStyle || '') &&
        'padding-bottom: 1rem;'}
      }
      ${theme.mq('lg')} {
        margin-top: ${getSpacing(waveType, '-3rem', '-1.25rem')};
        ${['mint', 'mintAlt'].includes(backgroundStyle || '') &&
        'padding-bottom: 0;'}
      }
      ${theme.mq('xl')} {
        margin-top: ${getSpacing(waveType, '-6rem', '-1.25rem')};
      }
      ${ScalableWaveSvg}
    `;
  }};
`;

const ScalableWaveEnd = styled.div<{ waveType: string; isFullWidth?: boolean }>`
  margin-bottom: ${({ waveType }) => getSpacing(waveType, '-2rem', '-1.75rem')};

  ${({ theme }) => theme.mq('md')} {
    margin-bottom: ${({ waveType }) =>
      getSpacing(waveType, '-4.5rem', '-2.25rem')};
  }

  ${({ theme }) => theme.mq('lg')} {
    margin-bottom: ${({ waveType }) =>
      getSpacing(waveType, '-1.5rem', '-0.75rem')};
  }

  ${({ theme }) => theme.mq('xl')} {
    margin-bottom: ${({ waveType }) =>
      getSpacing(waveType, '-2rem', '-0.75rem')};
  }

  ${({ isFullWidth }) => isFullWidth && ScalableWaveSvg};
`;

export {
  WaveSvg,
  FixedWave,
  FixedWaveContent,
  FixedWaveWaves,
  FixedWaveEnd,
  ScalableWave,
  ScalableWaveEnd,
};
