import debounce from 'lodash/debounce';
import { useState, useEffect, useRef, useCallback } from 'react';

const CAROUSEL_SLIDE_WIDTH = 300;
const DEBOUNCE_TIME_MS = 100;

export const useCarousel = (shouldLoop: boolean) => {
  const [carouselWidth, setCarouselWidth] = useState<number>(0);
  const [widthOfChildren, setWidthOfChildren] = useState<number>(0);
  const [slideOffset, setSlideOffset] = useState<number>(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const slideRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [validOffsets, setValidOffsets] = useState<number[]>([]);
  const getSlideOffset = () => Math.floor(slideRef.current?.scrollLeft ?? 0);

  const lastValidOffset =
    widthOfChildren > carouselWidth ? widthOfChildren - carouselWidth : 0;

  const initialiseCarousel = useCallback(() => {
    if (slideRef.current && contentRef.current && carouselRef.current) {
      setCarouselWidth(carouselRef.current.offsetWidth);

      const newValidOffsets: number[] = [0];

      let usedWindowWidth = 0;
      let currentWindowWidth = 0;

      let childrenInCurrentWindow = 0;
      const slideLeft =
        (slideRef.current?.getBoundingClientRect().left ?? 0) -
        getSlideOffset();
      for (let i = 0; i < contentRef.current.children.length; i += 1) {
        const child = contentRef.current.children[i] as HTMLElement;
        const childLeft = child.getBoundingClientRect().left - slideLeft;
        const childRight = child.getBoundingClientRect().right - slideLeft;

        const newWindowWidth = childRight - usedWindowWidth;

        let nextChildWillFit = true;
        if (
          i < contentRef.current.children.length - 1 &&
          childrenInCurrentWindow % 2 !== 0
        ) {
          const child2 = contentRef.current.children[i + 1] as HTMLElement;
          const child2Right = child2.getBoundingClientRect().right - slideLeft;
          const newWindowWidth2 = child2Right - usedWindowWidth;
          nextChildWillFit = newWindowWidth2 <= carouselRef.current.offsetWidth;
        }

        if (
          i !== 0 &&
          (!nextChildWillFit ||
            newWindowWidth > carouselRef.current.offsetWidth)
        ) {
          const delta =
            (carouselRef.current.offsetWidth - currentWindowWidth) / 2;
          newValidOffsets.push(Math.floor(childLeft - delta));
          usedWindowWidth = childLeft;
          childrenInCurrentWindow = 0;
        }
        childrenInCurrentWindow += 1;
        currentWindowWidth = childRight - usedWindowWidth;
      }

      setWidthOfChildren(contentRef.current.offsetWidth);
      setValidOffsets(newValidOffsets.length > 1 ? newValidOffsets : []);
      setSlideOffset(slideRef.current.scrollLeft);
    }
  }, [slideRef, contentRef, carouselRef]);

  useEffect(() => {
    const container = carouselRef.current;
    const content = contentRef.current;
    const slide = slideRef.current;
    if (!container || !content || !slide) return undefined;
    const resizeObserver = new ResizeObserver(() => {
      initialiseCarousel();
    });
    /*
     * listens to all scroll events,
     * espescially those created by created by child components
     */
    const scrollListener: EventListener = debounce((event: Event) => {
      if (event.type === 'scroll') {
        const target = event.target as HTMLElement;
        /*
         * intended to align slideOffset variable with actual scroll offset
         * in situations where scroll events are created by children
         * **does not actually scroll anything.**
         */
        setSlideOffset(target.scrollLeft);
      }
    }, DEBOUNCE_TIME_MS);
    slideRef.current.addEventListener('scroll', scrollListener);
    resizeObserver.observe(container);
    resizeObserver.observe(content);
    return () => {
      slide.removeEventListener('scroll', scrollListener);
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performScroll = (offset: number) => {
    slideRef.current?.scrollTo({
      left: offset,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    initialiseCarousel();
  }, [initialiseCarousel]);

  const onBackClick = () => {
    let prevOffset = getSlideOffset() - CAROUSEL_SLIDE_WIDTH;
    for (let i = 0; i < validOffsets.length; i += 1) {
      if (validOffsets[i] < getSlideOffset() - carouselWidth / 3) {
        prevOffset = validOffsets[i];
      } else {
        break;
      }
    }

    if (shouldLoop) {
      if (getSlideOffset() > 0) {
        performScroll(Math.max(prevOffset, 0));
      } else {
        performScroll(lastValidOffset);
      }
    } else {
      performScroll(Math.max(prevOffset, 0));
    }
  };

  const onNextClick = () => {
    let nextOffset = getSlideOffset() + CAROUSEL_SLIDE_WIDTH;

    for (let i = 0; i < validOffsets.length; i += 1) {
      if (validOffsets[i] > getSlideOffset() + carouselWidth / 3) {
        nextOffset = validOffsets[i];
        break;
      }
    }
    if (shouldLoop) {
      if (getSlideOffset() < lastValidOffset) {
        performScroll(Math.min(nextOffset, lastValidOffset));
      } else {
        performScroll(0);
      }
    } else {
      performScroll(Math.min(nextOffset, lastValidOffset));
    }
  };

  return {
    carouselRef,
    slideRef,
    contentRef,
    slideOffset,
    lastValidOffset,
    onBackClick,
    onNextClick,
  };
};
