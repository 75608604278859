import { Image } from '@silvertours/common-landingpages-view';
import {
  ImageWithFocalPoint,
  MediaLegacy,
} from '@silvertours/front-legacy-shared';
import { useCallback, useState } from 'react';
import { useContentModule, useSiteInfo } from '@silvertours/front-entities';
import { SearchForm } from '../SearchForm';
import { Wrapper, TrustpilotWidget } from './SearchContainer.styles';
import { SearchWizard, useSearchWizard } from '../SearchWizard';
import { matchError, useSubmitHandler } from '../SearchFormLegacy';
import { useSearchContext } from '../SearchFormLegacy/context';
import { isFormError } from '../SearchFormLegacy/context/types';

type Props = {
  image: MediaLegacy.ImageProps | Image;
  includeSearch?: boolean;
};

const SearchContainer = ({ image, includeSearch }: Props) => {
  const { featureToggles } = useSiteInfo();
  const [showWizard, setShowWizard] = useState(false);

  const action = () => {
    setShowWizard(true);
  };

  const { state, setError } = useSearchContext();
  const submitHandler = useSubmitHandler();
  const { getTrustpilotData } = useContentModule();
  const trustpilotProps = getTrustpilotData();

  const [loading, setLoading] = useState(false);

  const onSuccess = useCallback(() => {
    setLoading(false);
  }, []);

  const onError = useCallback((error: any) => {
    setLoading(false);
    const { type: kind, message } = matchError(error);

    if (isFormError(kind)) {
      setError({ kind, message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { scenes } = useSearchWizard();

  if (showWizard) {
    return (
      <SearchWizard
        loading={loading}
        scenes={scenes}
        onClose={() => setShowWizard(false)}
        onConfirm={() => {
          setLoading(true);
          submitHandler(state, '', onSuccess, onError);
        }}
      />
    );
  }

  return (
    <Wrapper>
      <ImageWithFocalPoint {...image} id="stage-image-mobile" priority />
      {includeSearch && <SearchForm searchAction={action} />}
      {featureToggles.trustElementOnStageImage && trustpilotProps && (
        <TrustpilotWidget {...trustpilotProps} />
      )}
    </Wrapper>
  );
};

export { SearchContainer };
