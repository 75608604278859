import type { LogoProps } from '../types';
import { Svg } from '../ThirdPartyLogo.styles';

const viewBox = {
  both: '0 0 137 49',
  vertical: '21 0 94 49',
  horizontal: '0 8.7 137 31',
  none: '21 8.7 94 31',
};

const AvisLogo = ({ withPadding = 'both', ...props }: LogoProps) => (
  <Svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox[withPadding]}
    role="img"
    aria-hidden="true"
  >
    <title>Avis</title>
    <path
      fill="#d70926"
      d="M103.23 16.65c-1.73-1.65-3.3-2.2-5-2.2-1.68 0-3.8.62-3.8 2.91 0 1.92 1.62 2.57 4.2 3.44 3.68 1.27 8.44 2.9 8.44 8.68 0 6.67-5.03 9.66-10.4 9.66-3.89 0-7.8-1.51-10.18-4.17l4.02-4.41c1.6 1.84 4 2.8 6.15 2.8 2 0 3.77-.81 3.77-3.14 0-2.22-2.08-2.96-5.65-4.19-3.45-1.18-6.95-3.02-6.95-8.26 0-6.43 5.45-9.1 10.52-9.1a13.1 13.1 0 0 1 8.9 3.57l-4.02 4.4ZM38.97 27.1h-6.83l3.45-9.95 3.38 9.95Zm11.21 11.3L39.06 9.41h-6.54L21 38.41h7.26l2-5.7h10.52l2.11 5.7h7.3ZM73.51 9.41h-7.25l-6.3 20.95-6.41-20.95h-7.37l10.29 29h6.56l10.48-29Zm2.63 29h6.6v-29h-6.6v29Zm33.8-26.53a2.37 2.37 0 0 0 2.33 2.45 2.38 2.38 0 0 0 2.33-2.45 2.4 2.4 0 0 0-2.33-2.47c-1.28 0-2.32 1.1-2.32 2.47Zm.38 0c0-1.15.87-2.09 1.95-2.09s1.96.94 1.96 2.09c0 1.14-.88 2.09-1.96 2.09s-1.95-.95-1.95-2.09Zm1.05 1.38h.47V12.1h.34l.6 1.16h.57l-.7-1.22c.37-.08.6-.35.6-.75 0-.6-.48-.81-.98-.81h-.9v2.78Zm.47-1.57v-.79h.42c.25 0 .52.08.52.38 0 .34-.3.41-.58.41h-.36Z"
    />
  </Svg>
);

// eslint-disable-next-line import/no-default-export
export default AvisLogo;
