import { Link } from '@silvertours/common-landingpages-view';

import { InteractiveText } from '@silvertours/front-shared';
import { ButtonRules } from './Button';
import { StyledAnchor, StyledMaskedLink } from './LinkButton.styles';

export type LinkButtonProps = {
  className?: string;
  content?: Link;
  rules?: ButtonRules;
  variant?: 'primary' | 'secondary';
};

const LinkButton = ({
  className,
  content,
  variant = 'primary',
  rules,
}: LinkButtonProps) => {
  if (!content) {
    return null;
  }

  const { href, label, target, rel } = content;
  const {
    horizontalSpacing: { isCentered = false } = {},
    size = 'large',
    expanded = false,
  } = rules || {};
  const isSmallButton = size === 'small';
  const isSecondaryButton = variant === 'secondary';

  return content.masked ? (
    <StyledMaskedLink
      body={
        <InteractiveText
          inline
          textColor="current"
          size={isSmallButton ? 'small' : 'normal'}
        >
          {label}
        </InteractiveText>
      }
      className={className}
      href={href}
      target={target}
      rel={rel}
      isCentered={isCentered}
      isSmallButton={isSmallButton}
      isSecondaryButton={isSecondaryButton}
    />
  ) : (
    <StyledAnchor
      className={className}
      href={href}
      target={target}
      rel={rel}
      isCentered={isCentered}
      isSmallButton={isSmallButton}
      isSecondaryButton={isSecondaryButton}
      isExpanded={expanded}
    >
      <InteractiveText
        inline
        textColor="current"
        size={isSmallButton ? 'small' : 'normal'}
      >
        {label}
      </InteractiveText>
    </StyledAnchor>
  );
};

export { LinkButton };
