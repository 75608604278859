import { String, Subtitle, Button, Theme } from '@silvertours/front-shared';
import Image from 'next/image';
import { useMedia } from 'react-use';
import { useTranslations } from 'next-intl';
import { useSiteInfo } from '../../SiteInfo';
import {
  Anchor,
  ContactInfo,
  InteractivePhoneNumber,
  PopoverWrapper,
  StyledOpeningTimesSchedule,
  VoucherExplanation,
  VoucherInfo,
} from './SupportPopover.styles';
import email from './email.png';
import call from './call.png';

type SupportPopoverProps = {
  showHeader?: boolean;
  showNumber?: boolean;
};

const SupportPopover = ({
  showHeader = true,
  showNumber = true,
}: SupportPopoverProps) => {
  const {
    settings: { phoneNumber, language },
  } = useSiteInfo();

  const formattedNumber = String.parsePhoneNumber(phoneNumber, language);

  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('lg')}px)`,
    false,
  );
  const t = useTranslations('features.supportInfo');

  const linkProps = {
    target: '_blank',
    rel: 'noopener noreferrer nofollow',
    fullWidth: true,
  };

  return (
    <PopoverWrapper onTouchMove={e => e.stopPropagation()}>
      <ContactInfo>
        {showHeader && <Subtitle as="p">{t('headline')}</Subtitle>}

        <Image src={email} alt="" width="185" height="150" />
        <Button variant="primary" href={t('contact.buttonHref')} {...linkProps}>
          {t('contact.buttonLabel')}
        </Button>

        {showNumber && (
          <>
            <Image src={call} alt="" width="185" height="150" />
            {isMobile ? (
              <Button href={formattedNumber.url} variant="secondary" fullWidth>
                {formattedNumber.national}
              </Button>
            ) : (
              <InteractivePhoneNumber textColor="green">
                <Anchor href={formattedNumber.url}>
                  {formattedNumber.national}
                </Anchor>
              </InteractivePhoneNumber>
            )}
            <StyledOpeningTimesSchedule />
          </>
        )}
      </ContactInfo>

      <VoucherInfo>
        <Button
          variant="secondary"
          href={t('voucher.buttonHref')}
          {...linkProps}
        >
          {t('voucher.buttonLabel')}
        </Button>
        <VoucherExplanation textColor="grey">
          {t('voucher.text')}
        </VoucherExplanation>
      </VoucherInfo>
    </PopoverWrapper>
  );
};

export { SupportPopover };
