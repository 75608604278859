// common type declaration for themes used by emotion https://emotion.sh/docs/typescript#define-a-theme
import { getLinearGradient } from './gradients';
import { mq } from './mediaQueries';

export const colors = [
  'brand',
  'brand80',
  'primaryHighlight',
  'secondary',
  'secondaryHighlight',
  // neutral colors
  'black',
  'text',
  'grey01',
  'grey02',
  'grey03',
  'grey04',
  'grey05',
  'white',
  // accent colors
  'info',
  'error',
  'success',
] as const;

export type Colors = (typeof colors)[number];

export const gradients = [
  'green',
  'orange',
  'mint',
  'mintAlt',
  'brand',
  'natural',
  'warning',
  'naturalLight',
] as const;

type Gradients = (typeof gradients)[number];

type Gradient = {
  from: string;
  via?: string;
  to: string;
};

export type ColorProps = { [key in Colors]?: string };

export type GradientProps = { [key in Gradients]?: Gradient };

type FontSizeProps = {
  [key in Breakpoints]: {
    size: string;
    lineHeight: string;
  };
};

type FontWeightProps = {
  body: 'normal' | 'bold';
  heading: 'normal' | 'bold';
};

const breakpoints = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'] as const;

type Breakpoints = (typeof breakpoints)[number];

export const spacingKeys = [
  0, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150,
] as const;

export type SpacingKeys = (typeof spacingKeys)[number];

type SpacingProps = {
  [key in SpacingKeys]: string;
};

type BoxShadowProps = {
  [key in 'sm' | 'md' | 'lg']: string;
};

type OptionalResponsiveProps = {
  [key in Breakpoints | 'none']?: string;
};

export interface Theme {
  color: ColorProps;
  gradient: GradientProps;
  fontFamily: {
    default: string;
    alternate: string;
  };
  fontSize: FontSizeProps;
  fontWeight: FontWeightProps;
  borderRadius: OptionalResponsiveProps;
  boxShadow: BoxShadowProps;
  spacing: SpacingProps;
  // utility functions
  mq: typeof mq;
  linearGradient: typeof getLinearGradient;
}

export interface ThemeProps {
  theme?: Theme;
}
