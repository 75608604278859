import { Check, Guard } from '@silvertours/lib-core-types';
import { Entity, EntityMemento } from '@silvertours/lib-sys-domain';

import {
  BmOkContent,
  ContentInterpolator,
  GoneContent,
  NotFoundContent,
  OkContent,
  PageContent,
  RedirectContent,
  StatusCode,
} from './content';
import { PageAttributes, PageAttributesOrganizer } from './pageAttributes';
import { ProductRef } from './product';
import { Stage } from './stage';
import { Body } from './body';
import { SupplementalContent } from './supplementalContent';

type LandingPageId = string;

type LandingPageMementoOfStatus<
  TContentOfStatus extends PageContent,
  TBody extends Record<string, any> = {},
> = {
  content: TContentOfStatus;
} & TBody;

type LandingPageOkMemento = LandingPageMementoOfStatus<
  OkContent,
  {
    attributes: PageAttributes;
    path: string;
    product: ProductRef;
    supplementalContent?: SupplementalContent;
  }
>;

type LandingPageRedirectMemento = LandingPageMementoOfStatus<RedirectContent>;

type LandingPageMemento =
  | LandingPageOkMemento
  | LandingPageMementoOfStatus<GoneContent>
  | LandingPageMementoOfStatus<NotFoundContent>
  | LandingPageRedirectMemento;

const isOfStatus = (
  { content }: LandingPageMemento,
  statusCode: StatusCode,
): boolean => content.statusCode === statusCode;

const isOk = (memento: LandingPageMemento): memento is LandingPageOkMemento =>
  isOfStatus(memento, StatusCode.Ok);

const isRedirect = (
  memento: LandingPageMemento,
): memento is LandingPageRedirectMemento =>
  isOfStatus(memento, StatusCode.PermanentRedirect) ||
  isOfStatus(memento, StatusCode.TemporaryRedirect);

const isRetired = (memento: LandingPageMemento) =>
  isOfStatus(memento, StatusCode.Gone) ||
  isOfStatus(memento, StatusCode.NotFound);

class LandingPage extends Entity<LandingPageId, LandingPageMemento> {
  private _attributeOganizer: PageAttributesOrganizer | undefined;
  private _contentInterpolator: ContentInterpolator | undefined;

  constructor(memento: EntityMemento<LandingPageId, LandingPageMemento>) {
    super(`LandingPage`, memento);
  }

  static Redirect(landingUrl: string, content: RedirectContent) {
    return new LandingPage({ id: landingUrl, content });
  }

  static Gone(landingUrl: string) {
    return new LandingPage({
      id: landingUrl,
      content: { landingUrl, statusCode: StatusCode.Gone },
    });
  }

  static NotFound(landingUrl: string) {
    return new LandingPage({
      id: landingUrl,
      content: { landingUrl, statusCode: StatusCode.NotFound },
    });
  }

  static Ok(
    landingUrl: string,
    content: OkContent,
    product: ProductRef,
    attributes: PageAttributes,
    supplementalContent?: SupplementalContent,
  ) {
    // @todo: Validation guard clauses
    return new LandingPage({
      id: landingUrl,
      attributes,
      content,
      path: landingUrl,
      product,
      supplementalContent,
    });
  }

  get slug() {
    return this.idRequired.replace(/\..*/g, '');
  }

  get statusCode() {
    return this.memento.content.statusCode;
  }

  get isOk() {
    return isOk(this.memento);
  }

  get isRedirect() {
    return isRedirect(this.memento);
  }

  get isRetired() {
    return isRetired(this.memento);
  }

  get redirectUrl() {
    return this.getIfRedirect(({ content }) => content.redirectUrl);
  }

  get alternativePaths() {
    return this.getIfOk(({ content }) => content.alternativePaths, []);
  }

  get headlines() {
    return this.getBmcgContentIfOk(({ headlines }) => headlines);
  }

  get path() {
    return this.getIfOk(({ path }) => path, '');
  }

  get body(): Body {
    return this.getIfOk(({ content }) => {
      return new Body(content, this.contentInterpolator);
    });
  }

  get meta() {
    return this.getIfOk(({ content }) => content.meta);
  }

  get stage(): Stage {
    return this.getIfOk(({ content }) => {
      return new Stage(content);
    });
  }

  get airport() {
    return this.getIfOk(({ attributes }) => attributes.airport, null);
  }

  get bookingAttribute() {
    return this.getIfOk(({ attributes }) => attributes.bookingAttributes, []);
  }

  get category() {
    return this.getIfOk(({ attributes }) => attributes.category, null);
  }

  get city() {
    return this.getIfOk(({ attributes }) => attributes.city, null);
  }

  get cityDistrict() {
    return this.getIfOk(({ attributes }) => attributes.cityDistrict, null);
  }

  get country() {
    return this.getIfOk(({ attributes }) => attributes.country, null);
  }

  get infoBanner() {
    return this.getIfOk(({ content }) => content.infoBanner);
  }

  get intro() {
    return this.getBmcgContentIfOk(({ introModule }) => introModule);
  }

  get island() {
    return this.getIfOk(({ attributes }) => attributes.island, null);
  }

  get partners() {
    return this.getIfOk(({ attributes }) => attributes.partners, []);
  }

  get railStation() {
    return this.getIfOk(({ attributes }) => attributes.railStation, null);
  }

  get state() {
    return this.getIfOk(({ attributes }) => attributes.state, null);
  }

  get navigation() {
    return this.getIfOk(({ supplementalContent }) => {
      return {
        staticLinks: supplementalContent?.navigation.staticLinks || [],
      };
    });
  }

  get stationsMap() {
    return this.getIfOk(({ supplementalContent }) => {
      return {
        stations: supplementalContent?.stations || [],
        boundingBox: this.boundingBox,
      };
    });
  }

  get priceGraph() {
    return this.getIfOk(({ supplementalContent }) => {
      return {
        priceInfos: supplementalContent?.priceInfos || [],
      };
    });
  }

  get supplierReviews() {
    return this.getIfOk(({ supplementalContent }) => {
      return {
        supplierReviews: supplementalContent?.supplierReviews || [],
      };
    });
  }

  get trustpilot() {
    return this.getIfOk(({ supplementalContent }) => {
      return {
        trustpilot: supplementalContent?.trustpilot || null,
      };
    });
  }

  get sliderCards() {
    return this.getIfOk(({ supplementalContent }) => {
      return {
        sliderCards: supplementalContent?.sliderCards || [],
      };
    });
  }

  get product() {
    return this.getIfOk(({ product }) => product);
  }

  get boundingBox() {
    return (
      this.island?.boundingBox ||
      this.cityDistrict?.boundingBox ||
      this.city?.boundingBox ||
      this.state?.boundingBox ||
      null
    );
  }

  get locationName() {
    return (
      this.city?.name ||
      this.state?.name ||
      this.country?.name ||
      this.island?.name ||
      this.airport?.city ||
      this.railStation?.cityName ||
      this.cityDistrict?.cityName ||
      ''
    );
  }

  get attributeSet() {
    return this.organizer.attributes;
  }

  get attributes() {
    return this.organizer.available;
  }

  get primaryAttribute() {
    return this.organizer.primary;
  }

  get secondaryAttribute() {
    return this.organizer.secondary;
  }

  get possibleAttributes() {
    return this.organizer.ordered;
  }

  /**
   * @remarks
   * A page is more specific when it has a greater number of associated entities
   * than the alternative.
   */
  isMoreSpecificThan(otherPage: LandingPage): boolean {
    return this.attributes.length > otherPage.attributes.length;
  }

  private get organizer() {
    if (!Check.isDefined(this._attributeOganizer)) {
      const attributes = this.getIfOk(({ attributes }) => attributes);

      this._attributeOganizer = new PageAttributesOrganizer(attributes);
    }

    return this._attributeOganizer;
  }

  private get contentInterpolator() {
    if (!Check.isDefined(this._contentInterpolator)) {
      this.getIfOk(() => {
        this._contentInterpolator = new ContentInterpolator(this);
      });
    }

    return this._contentInterpolator;
  }

  private getPropertyIfIsStatus<
    TMemento extends LandingPageMemento,
    TResult,
    TFallback extends TResult = TResult,
  >(
    isStatus: (memento: LandingPageMemento) => memento is TMemento,
    getProperty: (memento: TMemento) => TResult,
    fallback?: TFallback,
  ) {
    if (isStatus(this.memento)) {
      return getProperty(this.memento);
    }

    Guard.isDefined(fallback, {
      errorMessage:
        'Attempt to access a property which is not available for the given status',
    });

    return fallback;
  }

  private getBmcgContentIfOk<TResult, TFallback extends TResult = TResult>(
    getProperty: (content: BmOkContent) => TResult,
    fallback?: TFallback,
  ) {
    return this.getPropertyIfIsStatus(
      isOk,
      ({ content }) => {
        return getProperty(content);
      },
      fallback,
    );
  }

  private getIfOk<TResult, TFallback extends TResult = TResult>(
    getProperty: (memento: LandingPageOkMemento) => TResult,
    fallback?: TFallback,
  ) {
    return this.getPropertyIfIsStatus(isOk, getProperty, fallback);
  }

  private getIfRedirect<TResult>(
    getProperty: (memento: LandingPageRedirectMemento) => TResult,
  ) {
    return this.getPropertyIfIsStatus(isRedirect, getProperty);
  }
}

export { LandingPage };
export type { LandingPageId, LandingPageMemento };
