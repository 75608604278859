import styled from '@emotion/styled';

export const Svg = styled.svg`
  vertical-align: bottom;
`;

export const ButtonContainer = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
`;

export const BackgroundSVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
`;

export const ContentSVG = styled.svg`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 311px;
  height: 56px;
`;
