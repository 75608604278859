import { LocaleValue } from '@silvertours/common-landingpages-view';
import { FeatureToggles, InfolineTimes, SiteSettings } from './types';

type Defaults = {
  settings: SiteSettings;
  featureToggles: FeatureToggles;
  openingTimes: InfolineTimes;
};

const localeSpecificDefaultsMap: Record<
  LocaleValue,
  {
    settings: Partial<SiteSettings>;
    featureToggles: Partial<FeatureToggles>;
    openingTimes: Partial<InfolineTimes>;
  }
> = {
  'de-DE': {
    settings: {
      market: 'DE',
      language: 'de',
      phoneNumber: '+49 221 567 999 11',
    },
    featureToggles: {},
    openingTimes: {
      common: {
        always: {
          open: '08:00',
          close: '20:00',
        },
      },
    },
  },
  'fr-FR': {
    settings: {
      market: 'FR',
      language: 'fr',
      phoneNumber: '+33 1 73 79 33 33',
    },
    featureToggles: {
      hideInfoline: true,
      trustElementOnStageImage: false,
    },
    openingTimes: {
      common: {
        workdays: {
          open: '09:00',
          close: '18:00',
        },
      },
    },
  },
  en: {
    settings: {
      market: 'DE',
      language: 'en',
      phoneNumber: '',
    },
    featureToggles: {},
    openingTimes: {
      common: {},
    },
  },
};

export const getDefaults = (locale: LocaleValue) => {
  const localeSpecificDefaults = localeSpecificDefaultsMap[locale];

  const defaults: Defaults = {
    settings: {
      isAppMode: false,
      isTwa: false,
      isInternalCall: false,
      isInfolineCall: false,
      isBotCall: false,
      locale,
      market: 'DE',
      validMarkets: ['DE', 'FR'],
      validLanguages: ['de', 'fr'],
      language: 'de',
      phoneNumber: '+49 221 567 999 11',
      ...localeSpecificDefaults.settings,
      sessionData: {
        securityTokenValue: '',
        isMobileUserAgent: false,
        gtmEnvironment: 'live',
        anonymizeIP: true,
      },
    },
    featureToggles: {
      waitForResults: true,
      erv: true,
      maintenanceInfo: false,
      showScaMessage: false,
      hideInfoline: false,
      isLoginActive: true,
      useElasticSearch: false,
      showSurveyOnSuccess: false,
      showMarketPicker: true,
      showEnglishInMarketPicker: false,
      skipSecondScaCall: true,
      trustElementOnStageImage: true,
      ...localeSpecificDefaults.featureToggles,
    },
    openingTimes: {
      common: {
        always: {
          open: '08:00',
          close: '20:00',
        },
      },
      exceptions: [],
      ...localeSpecificDefaults.openingTimes,
    },
  };
  return defaults;
};
