const categoryMap = {
  M: 'Mini',
  N: 'Mini Elite',
  E: 'Economy',
  H: 'Economy Elite',
  C: 'Compact',
  D: 'Compact Elite',
  I: 'Intermediate',
  J: 'Intermediate Elite',
  S: 'Standard',
  R: 'Standard Elite',
  F: 'Fullsize',
  G: 'Fullsize Elite',
  P: 'Premium',
  U: 'Premium Elite',
  L: 'Luxury',
  W: 'Luxury Elite',
  O: 'Oversize',
  X: 'Special',
};

const typeMap = {
  B: '2-3 Door',
  C: '2/4 Door',
  D: '4-5 Door',
  W: 'Wagon/Estate',
  V: 'Passenger Van',
  L: 'Limousine',
  S: 'Sport',
  T: 'Convertible',
  F: 'SUV',
  J: 'Open Air All Terrain',
  X: 'Special',
  P: 'Pick up Regular Cab',
  Q: 'Pick up Extended Cab',
  Z: 'Special Offer Car',
  E: 'Coupe',
  M: 'Monospace',
  R: 'Recreational Vehicle',
  H: 'Motor Home',
  Y: '2 Wheel Vehicle',
  N: 'Roadster',
  G: 'Crossover',
  K: 'Commercial Van/Truck',
};

const transmissionDrivenWheelsMap = {
  M: 'Manual (drive unspecified)',
  N: 'Manual 4WD',
  C: 'Manual AWD',
  A: 'Auto (drive unspecified)',
  B: 'Auto 4WD',
  D: 'Auto AWD',
};

const fuelOrAirconMap = {
  R: 'Unspecified Fuel With Air Conditioning (AC)',
  N: 'Unspecified Fuel Without AC',
  D: 'Diesel With AC',
  Q: 'Diesel Without AC',
  H: 'Hybrid With AC',
  I: 'Hybrid Without AC',
  E: 'Electric With AC',
  C: 'Electric Without AC',
  L: 'LPG/Compressed Gas With AC',
  S: 'LPG/Compressed Gas Without AC',
  A: 'Hydrogen With AC',
  B: 'Hydrogen Without AC',
  M: 'Multi Fuel/Power With AC',
  F: 'Multi Fuel/Power Without AC',
  V: 'Petrol With AC',
  Z: 'Petrol Without AC',
  U: 'Ethanol With AC',
  X: 'Ethanol Without AC',
};

type AcrissClass = 'category' | 'type' | 'transmission' | 'fuelOrAircon';

const getMapper = (acrissClass: AcrissClass): { [key: string]: string } => {
  if (acrissClass === 'category') {
    return categoryMap;
  }
  if (acrissClass === 'type') {
    return typeMap;
  }
  if (acrissClass === 'transmission') {
    return transmissionDrivenWheelsMap;
  }
  if (acrissClass === 'fuelOrAircon') {
    return fuelOrAirconMap;
  }
  return {};
};

export class AcrissCode {
  acrissClass: AcrissClass;
  code: string;
  description: string;

  constructor(acrissClass: AcrissClass, code: string) {
    this.acrissClass = acrissClass;
    const mapper = getMapper(acrissClass);
    this.code = code;
    this.description = mapper[code];
  }
}

type FilterParam = {
  car_type?: string;
  car_class?: string;
};

const categoryToParam: { [key: string]: Record<string, string> } = {
  O: { car_class: 'premium_luxus' },
  X: { car_class: 'premium_luxus' },
  P: { car_class: 'premium_luxus' },
  U: { car_class: 'premium_luxus' },
  L: { car_class: 'premium_luxus' },
  W: { car_class: 'premium_luxus' },
  M: { car_class: 'mini_economy' },
  N: { car_class: 'mini_economy' },
  E: { car_class: 'mini_economy' },
  H: { car_class: 'mini_economy' },
  C: { car_class: 'compact' },
  D: { car_class: 'compact' },
  I: { car_class: 'intermediate_standard' },
  J: { car_class: 'intermediate_standard' },
  F: { car_class: 'standard_elite_full_size' },
  S: { car_class: 'intermediate_standard' },
  R: { car_class: 'intermediate_standard' },
  G: { car_class: 'standard_elite_full_size' },
};

const typeToParam: { [key: string]: Record<string, string> } = {
  T: { car_type: 'convertible' },
  W: { car_type: 'wagon_estate' },
  Y: { car_type: 'wagon_estate' },
  V: { car_type: 'van_monospace' },
  M: { car_type: 'van_monospace' },
  K: { car_type: 'truck' },
  F: { car_type: 'suv' },
  J: { car_type: 'suv' },
  P: { car_type: 'pickup' },
};

const transmissionToParam: { [key: string]: Record<any, any> } = {
  A: { transmission: 'automatic' },
  C: { car_type: '4wd_awd' },
  N: { car_type: '4wd_awd' },
  B: { car_type: '4wd_awd' },
  D: { car_type: '4wd_awd' },
};

const fuelToParam: { [key: string]: Record<string, string> } = {
  E: { electric_or_hybrid: 'true' },
  C: { electric_or_hybrid: 'true' },
  H: { electric_or_hybrid: 'true' },
  I: { electric_or_hybrid: 'true' },
};

export class AcrissFilter {
  private categories: AcrissCode[];
  private types: AcrissCode[];
  private transmissions: AcrissCode[];
  private fuels: AcrissCode[];
  private params: FilterParam[];

  constructor(acrissFilter: string) {
    const acrissCodeLists = acrissFilter
      .split(':')
      .map(string => string.split('|'));

    const [categories, types, transmissionDrive, fuelAircon] = acrissCodeLists;
    this.categories = categories.map(
      category => new AcrissCode('category', category),
    );
    this.types = types.map(type => new AcrissCode('type', type));
    this.transmissions = transmissionDrive.map(
      item => new AcrissCode('transmission', item),
    );
    this.fuels = fuelAircon.map(fuel => new AcrissCode('fuelOrAircon', fuel));
    this.params = [];
  }

  public getQueryParam(): FilterParam[] {
    if (this.categories.length) {
      const param = this.categories.find(
        category => categoryToParam[category.code],
      );
      if (param) {
        this.params.push(categoryToParam[param.code]);
      }
    }

    if (this.types.length) {
      const param = this.types.find(type => typeToParam[type.code]);
      if (param) {
        this.params.push(typeToParam[param.code]);
      }
    }

    if (this.fuels.length) {
      const param = this.fuels.find(type => fuelToParam[type.code]);
      if (param) {
        this.params.push(fuelToParam[param.code]);
      }
    }

    if (this.transmissions.length) {
      const param = this.transmissions.find(
        type => transmissionToParam[type.code],
      );
      if (param) {
        this.params.push(transmissionToParam[param.code]);
      }
    }

    return this.params;
  }
}

export class AcrissQueryMapper {
  private category: string;
  private type: string;

  constructor(acrissCode: string) {
    this.category = acrissCode[0];
    this.type = acrissCode[1];
  }

  public mapToQuery() {
    if (typeToParam[this.type]) {
      return new URLSearchParams(typeToParam[this.type]).toString();
    }
    return new URLSearchParams(categoryToParam[this.category]).toString();
  }
}
