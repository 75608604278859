import { useCallback } from 'react';
import type { FC, HTMLAttributes } from 'react';
import { useTranslations } from 'next-intl';
import { format } from 'date-fns/format';
import styled from '@emotion/styled';
import { String } from '@silvertours/front-shared';
import { useSiteInfo } from '../SiteInfo';
import { useLocale } from '../Runtime';

const Schedule = styled.ul`
  margin-block: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.default};
  line-height: 1.5;
  color: ${({ theme }) => theme.color.black};
`;

const Item = styled.li<{ $type: 'common' | 'exception' }>`
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  font-weight: ${({ $type }) => ($type === 'common' ? 'bold' : '')};
`;

type Props = Pick<HTMLAttributes<HTMLUListElement>, 'className'>;

export const OpeningTimesSchedule: FC<Props> = ({ className }) => {
  const t = useTranslations('entities.openingTimes');
  const { openingTimes } = useSiteInfo();
  const locale = useLocale();
  const { dateLong } = String.dateFormats[locale.language];

  const formatDate = useCallback(
    (date: string) => format(date, dateLong),
    [dateLong],
  );

  if (!openingTimes.common) {
    return null;
  }

  const labels = {
    always: t('dayRange.always'),
    workdays: t('dayRange.workdays'),
    weekends: t('dayRange.weekends'),
    saturday: t('dayRange.saturday'),
    sunday: t('dayRange.sunday'),
  };

  return (
    <Schedule aria-label={t('title')} className={className}>
      {Object.entries(openingTimes.common).map(([label, times]) => (
        <Item $type="common" key={label}>
          <span>{labels[label as unknown as keyof typeof labels]}</span>
          <time>{t('timeRange', times)}</time>
        </Item>
      ))}
      {Object.entries(openingTimes.exceptions).map(([date, times]) => (
        <Item $type="exception" key={date}>
          <time dateTime={date}>{formatDate(date)}</time>
          {times ? (
            <time>{t('timeRange', times)}</time>
          ) : (
            <span>{t('closed')}</span>
          )}
        </Item>
      ))}
    </Schedule>
  );
};
