import { Carousel, SliderCard } from '@silvertours/front-entities';
import { Theme } from '@silvertours/front-shared';
import { SliderCard as SliderCardType } from '@silvertours/back-domain-page';
import { useMedia } from 'react-use';
import { Container, Wrapper } from './MarketingSlider.styles';

export type Props = {
  sliderCards?: SliderCardType[];
};

const MarketingSlider = ({ sliderCards }: Props) => {
  const CarouselContainer = Container;
  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('xl')}px)`,
    true,
  );

  const isSmall = useMedia(`(max-width: ${Theme.getBreakpoint('sm')}px)`, true);

  const visibleItemsAmount = isMobile ? 1 : 3;

  if (!sliderCards || !sliderCards.length) {
    return null;
  }

  return (
    <Wrapper id="cardsSlider">
      <CarouselContainer
        as={Carousel}
        hasItemsToReveal={sliderCards.length > visibleItemsAmount}
        showSlideBackground={false}
        prevText="prev"
        nextText="next"
      >
        {sliderCards.map(card => (
          <SliderCard
            key={card.title}
            text={card.text}
            image={card.image}
            linkText={card.linkText}
            linkHref={card.href}
            isSmall={isSmall}
            title={card.title}
          />
        ))}
      </CarouselContainer>
    </Wrapper>
  );
};

export { MarketingSlider };
