import type { MouseEvent } from 'react';
import { useTranslations } from 'next-intl';
import { analytics } from '@silvertours/front-entities';
import Search from '@engineering/icons/search';
import { useSearchContext } from '../../SearchFormLegacy/context';
import {
  Form,
  Headline,
  Icon,
  AccessibleLabel,
  Placeholder,
  SearchButton,
} from './Form.styles';

type Props = {
  searchAction?: () => void;
};

export const SearchForm = ({ searchAction }: Props) => {
  const t = useTranslations('features.stage');
  const { state } = useSearchContext();

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    analytics.gtm.trackBeginSearchClicked();
    if (searchAction) {
      searchAction();
    }
  };

  const value = state?.inputValue.departure;
  const hasValue = !!value;

  return (
    <Form id="search-wizard" role="search" as="form">
      <Headline>{t('search.headline')}</Headline>
      <SearchButton
        type="button"
        aria-labelledby="search-label-text"
        aria-haspopup="true"
        onClick={handleSubmit}
        hasValue={hasValue}
      >
        <Icon aria-hidden="true">
          <Search width="24" height="24" />
        </Icon>
        <Placeholder id={hasValue ? undefined : 'search-placeholder-text'}>
          {value || t('search.input.placeholder')}
        </Placeholder>
        <AccessibleLabel id="search-label-text">
          {t('search.input.label')}
        </AccessibleLabel>
      </SearchButton>
    </Form>
  );
};
