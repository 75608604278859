import { Entity, EntityMemento } from '@silvertours/lib-sys-domain';

type RentalOfferId = string;

type RentalOfferAttributes = {
  seats: number;
  doors: string;
  airConditioning: boolean;
  transmission: string;
};

type RentalOfferMemento = EntityMemento<
  RentalOfferId,
  {
    offerId: string;
    category: string;
    city: string;
    cityCode: string;
    departureDate: string;
    destinationDate: string;
    price: number;
    supplier: string;
    storedAt: string;
    carName: string;
    originalImage: string;
    attributes: RentalOfferAttributes | null;
  }
>;

const CarCategoryParam: Record<string, string> = {
  compact: 'car_class',
  convertible: 'car_type',
  intermediate_standard: 'car_class',
  standard_elite_full_size: 'car_class',
  mini_economy: 'car_class',
  premium_luxus: 'car_class',
  suv: 'car_type',
  van_monospace: 'car_type',
  truck: 'car_type',
  wagon_estate: 'car_type',
};

class RentalOffer extends Entity<RentalOfferId, RentalOfferMemento> {
  constructor(memento: RentalOfferMemento) {
    super('RentalOffer', memento);
  }

  static Bind(memento: RentalOfferMemento) {
    return new RentalOffer(memento);
  }

  static generateSearchQuery(category: string) {
    const param = CarCategoryParam[category];
    if (!param) {
      return '';
    }
    return new URLSearchParams(`${param}=${category}`).toString();
  }

  get category() {
    return this.memento.category;
  }

  get city() {
    return this.memento.city;
  }

  get cityCode() {
    return this.memento.cityCode;
  }

  get departureDate() {
    return this.memento.departureDate;
  }

  get destinationDate() {
    return this.memento.destinationDate;
  }

  get image() {
    return `/static/default/global/graphic/car/car_classes/${this.memento.category}.png`;
  }

  get originalImage() {
    return this.memento.originalImage;
  }

  get carName() {
    return this.memento.carName;
  }

  get attributes() {
    return this.memento.attributes;
  }

  get price() {
    return this.memento.price;
  }

  get storedAt() {
    return this.memento.storedAt;
  }

  get supplier() {
    return this.memento.supplier;
  }
}

export { RentalOffer };
export type { RentalOfferAttributes, RentalOfferId, RentalOfferMemento };
