export type AppLogoProps = {
  className?: string;
};

export const AppLogo = ({ className }: AppLogoProps) => (
  <svg
    className={className}
    width="148"
    height="148"
    viewBox="0 0 148 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2225_46990)" filter="url(#filter0_d_2225_46990)">
      <path
        d="M52.7709 91.26C53.5366 92.2445 52.8803 93.7915 51.5677 93.7915H31.5497C30.5652 93.7915 29.6744 93.2446 29.2369 92.3539C17.2511 59.6781 36.8315 25.6897 75.1017 25.5803H139.938C139.25 15.2041 130.655 6.98438 120.091 6.98438H27.9086C16.9073 7 8 15.9073 8 26.9086V101.714H72.242C100.402 101.714 108.106 73.0078 95.0103 54.74C94.2446 53.7555 94.9009 52.2085 96.2136 52.2085H116.232C117.216 52.2085 118.107 52.7554 118.544 53.6461C130.53 86.3219 110.95 120.31 72.6795 120.42H8.06251C8.75009 130.796 17.3449 139.016 27.9086 139.016H120.091C131.093 139.016 140 130.108 140 119.107V44.2857H75.5392C47.3797 44.2857 39.6756 72.9922 52.7709 91.26Z"
        fill="#00977d"
      />
      <path
        d="M118.544 53.6462C118.107 52.7711 117.232 52.2085 116.232 52.2085H96.2136C94.9009 52.2085 94.229 53.7556 95.0103 54.74C108.106 72.9922 100.402 101.714 72.242 101.714H8V119.091C8 119.545 8.03125 119.967 8.06251 120.404H72.6795C110.965 120.295 130.546 86.3063 118.544 53.6305V53.6462Z"
        fill="white"
      />
      <path
        d="M139.938 25.5959H75.1017C36.8159 25.7053 17.2355 59.6937 29.2369 92.3695C29.6744 93.2446 30.5495 93.8072 31.5497 93.8072H51.5677C52.8803 93.8072 53.5523 92.2601 52.7709 91.2756C39.6756 73.0234 47.3797 44.3013 75.5393 44.3013H140V26.9242C140 26.4711 139.969 26.0491 139.938 25.6116V25.5959Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2225_46990"
        x="0"
        y="0"
        width="148"
        height="148"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2225_46990"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2225_46990"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_2225_46990">
        <rect
          width="132"
          height="132"
          fill="white"
          transform="translate(8 7)"
        />
      </clipPath>
    </defs>
  </svg>
);
