import { ContentModules } from '@silvertours/common-landingpages-view';

import { Features } from '@silvertours/common-landingpages-i18n';
import { AppPromoModule } from './appPromo';
import { StationsMap } from './stationsMap';
import { Faq } from './faq';
import { LinkLists } from './linkLists';
import { StandardContent } from './standardContent';
import { Tabs } from './tabs';
import { TextImage } from './textImage';
import { PriceGraph } from './priceGraph';
import { SupplierReviews } from './supplierReviews/SupplierReviews';

export const renderContentModule = (
  module: ContentModules,
  translations: Features['article'],
  searchQuery?: string,
) => {
  switch (module.moduleType) {
    case 'appPromoModule':
      return <AppPromoModule {...module} />;
    case 'faqs':
      return <Faq {...module} translations={translations.faq} />;
    case 'linkListGroup':
      return <LinkLists {...module} translations={translations.linklists} />;
    case 'standardContent':
      return (
        <StandardContent
          {...module}
          translations={translations.standardContent}
        />
      );
    case 'stationsMap':
      return <StationsMap {...module} />;
    case 'priceGraph':
      return (
        <PriceGraph
          {...module}
          translations={translations.pricegraph}
          searchQuery={searchQuery}
        />
      );
    case 'customerReviews':
      return (
        <SupplierReviews
          {...module}
          translations={translations.supplierReviews}
        />
      );
    case 'tabs':
      return (
        <Tabs
          {...module}
          translations={{
            ...translations.tabs,
            linklist: translations.linklists,
          }}
        />
      );
    case 'textImage':
    default:
      return <TextImage {...module} />;
  }
};
