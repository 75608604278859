import Image from 'next/image';
import { gtm } from '../analytics';
import { Container, LinkOnlyContainer } from './SliderCard.styles';

type Props = {
  title: string;
  text?: string;
  image?: string;
  linkText?: string;
  linkHref?: string;
  isSmall?: boolean;
};

const renderCard = (
  title: string,
  text: string,
  linkText?: string,
  linkHref?: string,
  image?: string,
  isSmall?: boolean,
) => (
  <Container>
    {image && (
      <Image
        src={image}
        alt=""
        width={isSmall ? '116' : '144'}
        height={isSmall ? '116' : '144'}
      />
    )}
    <div>
      <p>{text}</p>
      {linkHref && linkText && (
        <a
          href={linkHref}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            gtm.trackSliderCard({ title });
          }}
        >
          {linkText}
        </a>
      )}
    </div>
  </Container>
);

const renderLinkOnlyCard = (
  title: string,
  linkText: string,
  linkHref: string,
) => (
  <LinkOnlyContainer>
    <a
      href={linkHref}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        gtm.trackSliderCard({ title });
      }}
    >
      {linkText}
    </a>
  </LinkOnlyContainer>
);

const SliderCard = ({
  title,
  text,
  linkText,
  linkHref,
  image,
  isSmall,
}: Props) => {
  if (linkHref && linkText && !text) {
    return renderLinkOnlyCard(title, linkText, linkHref);
  }
  return renderCard(
    title,
    text || '',
    linkText || '',
    linkHref,
    image,
    isSmall,
  );
};

export { SliderCard };
