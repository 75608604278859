import { FC, ReactNode, useEffect } from 'react';

import { HasCommonContent } from '@silvertours/common-landingpages-view';
import { Runtime, analytics } from '@silvertours/front-entities';
import {
  PageLegacy,
  PageNavigationLegacy,
  Suppliers,
  MarketingSlider,
  AppInstallBanner,
  useShowAppBanner,
} from '@silvertours/front-features';
import { ContentModulesRenderer } from './content';
import { ContentModules } from './ContentModules';

type BodyProps = HasCommonContent & {
  stage?: ReactNode;
  title?: string;
  content: ContentModulesRenderer;
  includeSearch?: boolean;
  includeTrustSeals?: boolean;
  isMinimal?: boolean;
  isPlain?: boolean;
};

const Body: FC<BodyProps> = ({
  title,
  breadcrumbs,
  content,
  suppliers,
  sliderCards,
  stage,
  includeSearch = true,
  includeTrustSeals = true,
  isMinimal = false,
  isPlain = false,
}) => {
  const { language } = Runtime.useLocale();
  const { isShowing, onClose } = useShowAppBanner(language);

  useEffect(() => {
    if (isShowing) {
      analytics.gtm.trackAppPromoBannerViewed();
    }
  }, [isShowing]);

  return (
    <PageLegacy.PageContentBase>
      {stage}
      <Suppliers.Suppliers
        title={title}
        suppliers={suppliers.content}
        includeSearch={includeSearch}
        includeTrustSeals={includeTrustSeals}
        isMinimal={isMinimal}
        isPlain={isPlain}
      />
      {!isPlain && !isMinimal && (
        <MarketingSlider.MarketingSlider
          sliderCards={sliderCards?.content?.sliderCards}
        />
      )}
      {breadcrumbs && !isPlain && (
        <PageNavigationLegacy.Breadcrumbs breadcrumbs={breadcrumbs.links} />
      )}
      <ContentModules content={content} />
      <>
        {/* app install banner */}
        <div id="fglayer" role="application" />
        <AppInstallBanner isShowing={isShowing} onClose={onClose} />
      </>
    </PageLegacy.PageContentBase>
  );
};
export { Body };
