import styled from '@emotion/styled';
import { RichTextStyle } from '../RichText';

const NavContainer = styled.div`
  width: 100%;
`;

const Nav = styled.nav<{ textStyle: RichTextStyle }>`
  border-bottom: ${({ textStyle, theme }) =>
    `1px solid ${
      textStyle === RichTextStyle.Alternative
        ? theme.color.white
        : theme.color.grey04
    }`};
  margin-bottom: ${({ theme }) => theme.spacing[40]};
  gap: ${({ theme }) => theme.spacing[70]};
  padding-right: ${({ theme }) => theme.spacing[70]};
  scroll-behavior: smooth;
  scrollbar-width: none;
  overflow: auto clip;
  display: flex;
`;

export { Nav, NavContainer };
