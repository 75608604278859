/* eslint-disable react/no-danger */

type StructuredDataType<
  TData extends Record<string, unknown> | Array<Record<string, unknown>>,
> = Partial<{
  '@type': string;
  '@context': 'https://schema.org';
}> &
  TData;

type StructuredDataProps<TData extends Record<string, unknown>> = {
  type: string;
  subType?: string;
  data: TData | TData[];
  includeContext?: boolean;
};

const CONTEXT = {
  '@context': 'https://schema.org',
};

const StructuredData = <TData extends Record<string, unknown>>({
  type,
  subType,
  data,
  includeContext = false,
}: StructuredDataProps<TData>) => {
  const context = includeContext ? CONTEXT : {};
  const subTypeId = subType ? `-${subType.toLowerCase()}` : '';
  const createData = (item: TData) => ({ '@type': type, ...item, ...context });
  const structuredData: StructuredDataType<TData | TData[]> = Array.isArray(
    data,
  )
    ? data.map(createData)
    : createData(data);

  return (
    <script
      id={`jsonld-${type.toLowerCase()}${subTypeId}`}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
    />
  );
};

export { StructuredData };
export type { StructuredDataProps, StructuredDataType };
