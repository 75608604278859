import { useTranslations } from 'next-intl';
import {
  ContentLayout,
  TransporterPageContent,
} from '@silvertours/common-landingpages-view';
import {
  BenefitsLegacy,
  ContentArticle,
  StageLegacy,
  SerpOffersModule,
  OffersModule,
} from '@silvertours/front-features';

import { Body } from '../body';

const TransporterContent = ({
  content: {
    article,
    globalRating,
    introduction,
    links,
    location,
    offersByCategory,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<TransporterPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);
  const offersTranslations = useTranslations('features.offers');

  return (
    <Body
      title={primaryHeadline}
      stage={
        <StageLegacy.Stage content={stage.content} headline={primaryHeadline} />
      }
      content={({ container, containerModule, contentModule }) => [
        containerModule(
          'usp',
          <BenefitsLegacy.Usp rating={globalRating.content} />,
        ),
        containerModule(
          'introduction-content',
          <ContentArticle
            modules={introduction?.segments ?? []}
            translations={translations}
          />,
          !!introduction?.segments,
        ),
        containerModule(
          'serpOffers',
          !!offersByCategory.content && (
            <SerpOffersModule
              content={offersByCategory.content}
              translations={{
                airConditioning: offersTranslations('car.airConditioning'),
                carTypes: t.raw('offers.car.type' as any),
                prevText: offersTranslations('prevText'),
                nextText: offersTranslations('nextText'),
                searchButtonText: offersTranslations('searchButtonText'),
                disclaimertext: offersTranslations('disclaimerSerpOffers'),
                pricePrefix: offersTranslations('pricePrefix'),
                priceSuffix: offersTranslations('priceSuffix'),
                serpTitle: location.name
                  ? offersTranslations(
                      'serp.title.transporterDestination',
                    ).replace('%LOCATION%', location.name)
                  : offersTranslations('serp.title.transporter'),
                transmissionAutomatic: offersTranslations(
                  'car.transmissionAutomatic',
                ),
                transmissionManual: offersTranslations(
                  'car.transmissionManual',
                ),
              }}
              searchQuery={new URLSearchParams({
                car_class: 'truck',
              }).toString()}
            />
          ),
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        containerModule(
          'offers',
          !!offersByCategory.content && (
            <OffersModule
              content={offersByCategory.content}
              translations={{
                disclaimer: offersTranslations('disclaimer'),
                disclaimerOverlayCTA: offersTranslations(
                  'disclaimerOverlayCTA',
                ),
                durationFrom: offersTranslations('durationFrom'),
                headline: location.name
                  ? offersTranslations(
                      'headline.transporterDestination',
                    ).replace('%LOCATION%', location.name)
                  : offersTranslations('headline.transporter'),
                pricePrefix: offersTranslations('pricePrefix'),
                priceSuffix: offersTranslations('priceSuffix'),
                searchButtonText: offersTranslations('searchButtonText'),
                badgeText: offersTranslations('badge'),
                offerDetailsTitle: offersTranslations('offerDetailsTitle'),
                carTypes: t.raw('offers.car.type' as any),
              }}
            />
          ),
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        container('content-modules', [
          contentModule(
            'article',
            <ContentArticle
              modules={article.segments}
              translations={translations}
              searchQuery={new URLSearchParams({
                car_class: 'truck',
              }).toString()}
            />,
          ),
        ]),
        containerModule(
          'linkLists',
          <ContentArticle
            modules={links.content ?? []}
            translations={translations}
          />,
          !!links.content.length,
        ),
      ]}
      {...commonProps}
    />
  );
};

export { TransporterContent };
