import { MouseEventHandler, useRef } from 'react';

import { ActiveButton, InactiveButton } from './TabButton.styles';
import { RichTextStyle } from '../RichText';
import { InteractiveText } from '../../Text';

export type TabBehavior = {
  onSelectTab: (tabName: string) => void;
};

export type TabRules = {
  activeTabName: string;
  textStyle?: RichTextStyle;
};

export type TabContent = {
  name: string;
  position: number;
  tabGroup: string;
};

export type TabProps = {
  behavior: TabBehavior;
  content: TabContent;
  rules?: TabRules;
};

const TabButton = ({
  behavior: { onSelectTab },
  content: { name, position, tabGroup },
  rules,
}: TabProps) => {
  const { activeTabName, textStyle = RichTextStyle.Primary } = rules || {};
  const isCurrentTab = activeTabName === name;
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleTabClick: MouseEventHandler<HTMLButtonElement> = ev => {
    ev.preventDefault();
    onSelectTab(name);

    if (buttonRef.current) {
      const tabList = buttonRef.current.closest('[role="tablist"]');

      if (tabList) {
        // Save current scroll position of the window
        const { scrollX, scrollY } = window;

        // Calculate positions
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const containerRect = tabList.getBoundingClientRect();

        // Calculate the ideal scroll position to center the button in its container
        const targetScrollLeft =
          tabList.scrollLeft +
          (buttonRect.left - containerRect.left) -
          containerRect.width / 2 +
          buttonRect.width / 2;

        tabList.scrollLeft = targetScrollLeft;

        // Restore the window's scroll position
        window.scrollX = scrollX;
        window.scrollY = scrollY;
      }
    }
  };

  const Button = isCurrentTab ? ActiveButton : InactiveButton;

  return (
    <Button
      ref={buttonRef}
      aria-selected={isCurrentTab}
      aria-controls={`${tabGroup}-panel-${position}`}
      id={`${tabGroup}-tab-${position}`}
      key={name}
      onClick={handleTabClick}
      role="tab"
      type="button"
      textStyle={textStyle}
    >
      <InteractiveText inline textColor="current">
        {name}
      </InteractiveText>
    </Button>
  );
};

export { TabButton };
