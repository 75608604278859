import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import type {
  ReactNode,
  UIEventHandler,
  WheelEvent,
  WheelEventHandler,
} from 'react';
import { useMedia } from 'react-use';
import { Theme } from '@silvertours/front-shared';
import debounce from 'lodash/debounce';

import {
  ArrowBackIcon,
  ArrowNextIcon,
  ButtonWrapper,
  Caption,
  CardBackground,
  CarouselSlide,
  CarouselWrapper,
  ChildrenWrapper,
  SliderButton,
  SlideWrapper,
} from './Carousel.styles';
import { useCarousel } from './useCarousel';

const DEBOUNCE_TIME_MS = 100;

export type CarouselProps = {
  prevText: string;
  nextText: string;
  captionText?: string;
  children: ReactNode;
  shouldLoop?: boolean;
  showSlideBackground?: boolean;
  className?: string;
  onSlideChange?: (direction: 'forward' | 'backward') => void;
  onSlideDrag?: (offset: number) => void;
  onMouseWheel?: (offset: number) => void;
  hasItemsToReveal?: boolean;
  snap?: boolean;
};

export type CarouselRef = {
  slideRef: React.RefObject<HTMLDivElement>;
};

const Carousel = forwardRef<CarouselRef, CarouselProps>(
  (
    {
      prevText,
      nextText,
      captionText,
      children,
      shouldLoop = false,
      showSlideBackground = true,
      className,
      onSlideChange,
      onSlideDrag,
      onMouseWheel,
      hasItemsToReveal = true,
      snap = false,
    },
    ref,
  ) => {
    const isMobile = useMedia(
      `(max-width: ${Theme.getBreakpoint('lg')}px)`,
      true,
    );

    const {
      carouselRef,
      slideRef,
      contentRef,
      slideOffset,
      lastValidOffset,
      onBackClick,
      onNextClick,
    } = useCarousel(shouldLoop);

    const dragCallback: UIEventHandler<HTMLDivElement> = useCallback(() => {
      if (onSlideDrag && isMobile && slideRef.current?.scrollLeft) {
        onSlideDrag(slideRef.current?.scrollLeft);
      }
    }, [onSlideDrag, isMobile, slideRef]);

    const mouseWheelCallback: WheelEventHandler<HTMLDivElement> = useMemo(
      () =>
        debounce((e: WheelEvent<HTMLDivElement>) => {
          if (onMouseWheel && slideRef.current?.scrollLeft && e.deltaX !== 0) {
            onMouseWheel(slideRef.current?.scrollLeft);
          }
        }, DEBOUNCE_TIME_MS),
      [onMouseWheel, slideRef],
    );

    useImperativeHandle(ref, () => ({
      slideRef,
    }));

    const activeSlide = (
      <CarouselSlide
        ref={slideRef}
        onTouchEnd={dragCallback}
        onWheel={mouseWheelCallback}
        snap={snap}
      >
        <ChildrenWrapper ref={contentRef} className={className} snap={snap}>
          {children}
        </ChildrenWrapper>
      </CarouselSlide>
    );

    return (
      <CarouselWrapper ref={carouselRef}>
        <CardBackground showSlideBackground={showSlideBackground}>
          <SlideWrapper>{activeSlide}</SlideWrapper>
        </CardBackground>
        {hasItemsToReveal && (
          <ButtonWrapper>
            <SliderButton
              variant="secondary"
              icon={<ArrowBackIcon />}
              title={prevText}
              disabled={!shouldLoop && slideOffset === 0}
              onClick={() => {
                onBackClick();
                if (onSlideChange) onSlideChange('backward');
              }}
            />
            <SliderButton
              variant="secondary"
              icon={<ArrowNextIcon />}
              title={nextText}
              disabled={!shouldLoop && slideOffset === lastValidOffset}
              onClick={() => {
                onNextClick();
                if (onSlideChange) onSlideChange('forward');
              }}
            />
          </ButtonWrapper>
        )}
        <Caption>{captionText}</Caption>
      </CarouselWrapper>
    );
  },
);

export { Carousel };
