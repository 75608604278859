import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import {
  useEffect,
  useCallback,
  RefObject,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useIntersection } from 'react-use';
import { onINP } from 'web-vitals/attribution';

import { Session } from '@silvertours/front-shared';
import { trackCriteo, trackPageView, trackWebVitalsMetric } from './events';
import { getTrackingDataForPath } from './utils';
import type { DataLayerSettings } from './dataLayer';
import { useSiteInfo } from '../../SiteInfo/hooks/useSiteInfo';
import { SiteSettings } from '../../SiteInfo';

const mapDataLayerSettings = (
  siteSettings: SiteSettings,
): DataLayerSettings => ({
  environment: siteSettings?.sessionData?.gtmEnvironment,
  anonymizeIP: siteSettings?.sessionData?.anonymizeIP,
  locale: siteSettings?.language, // re-using "language" here to be consistent with previous implementation
  language: siteSettings?.language,
  market: siteSettings?.market,
});

/**
 * Store data in session storage used in carspa to track bookings
 */
const useChannelTracking = () => {
  useEffect(() => {
    const queryString = document.location.search;
    const { referrer } = document;

    if (!Session.SessionStorageManager.getData('channel_tracking')) {
      Session.SessionStorageManager.setData('channel_tracking', [
        {
          queryString,
          referrer,
        },
      ]);
    }
  }, []);
};

const usePageView = () => {
  const siteInfo = useSiteInfo();
  const router = useRouter();
  useChannelTracking();

  // Initialize the global tracking state
  useEffect(() => {
    if (typeof window !== 'undefined' && !window.GTM_TRACKING_STATE) {
      window.GTM_TRACKING_STATE = {
        trackedPaths: new Set(),
      };
    }
  }, []);

  // Track if we've handled the initial page load
  const initialLoadHandledRef = useRef(false);

  // Create the actual tracking function
  const trackPageViewImpl = useCallback(
    (path: string) => {
      if (!siteInfo.settingsLoaded) {
        return;
      }

      const settings = mapDataLayerSettings(siteInfo.settings);
      const data = getTrackingDataForPath(settings, path);
      trackPageView(data);

      if (!siteInfo.settings.isInternalCall) {
        trackCriteo();
      }
    },
    [siteInfo],
  );

  // Create a debounced version of the tracking function
  const debouncedTrackPageView = useMemo(
    () => debounce(trackPageViewImpl, 300),
    [trackPageViewImpl],
  );

  // Cleanup debounce on unmount
  useEffect(
    () => () => {
      debouncedTrackPageView.cancel();
    },
    [debouncedTrackPageView],
  );

  // Handle initial page view separately
  useEffect(() => {
    if (
      router.isReady &&
      siteInfo.settingsLoaded &&
      !initialLoadHandledRef.current
    ) {
      initialLoadHandledRef.current = true;
      trackPageViewImpl(router.asPath);
    }
  }, [
    router.isReady,
    router.asPath,
    trackPageViewImpl,
    siteInfo.settingsLoaded,
  ]);

  // Handle route changes with debounce
  useEffect(() => {
    const handleRouteChange = (path: string) => {
      debouncedTrackPageView(path);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, debouncedTrackPageView]);
};

declare global {
  interface Window {
    GTM_TRACKING_STATE?: {
      trackedPaths: Set<string>;
    };
  }
}

const useElementScrolledToView = (
  ref: RefObject<HTMLElement>,
  callback: (...args: any[]) => void,
) => {
  const [seen, setSeen] = useState(false);
  const intersection = useIntersection(ref, {
    root: null,
    threshold: 1,
  });

  useEffect(() => {
    if (intersection?.isIntersecting && !seen) {
      callback();
      setSeen(true);
    }
  }, [callback, intersection, seen]);
};

/** @deprecated Use `next/web-vitals` when Next.js 13+ is available. */
const useReportWebVitals = () => {
  useEffect(() => {
    onINP(trackWebVitalsMetric, { reportAllChanges: true });
    // onLCP(trackWebVitalsMetric);
    // onCLS(trackWebVitalsMetric);
    // onFID(trackWebVitalsMetric);
  });
};

export { usePageView, useElementScrolledToView, useReportWebVitals };
