import { SupportPopoverButton } from '@silvertours/front-entities';
import { Header as HeaderComponent } from './Header';
import type { Props } from './Header';
import { Navigation, LoginButton, LoggedInActions } from './components';

import { Separator } from './Header.styles';

const Header = HeaderComponent as React.FC<Props> & {
  LoginButton: typeof LoginButton;
  Navigation: typeof Navigation;
  Separator: typeof Separator;
  SupportPopoverButton: typeof SupportPopoverButton;
  LoggedInActions: typeof LoggedInActions;
};

Header.Navigation = Navigation;
Header.Separator = Separator;
Header.SupportPopoverButton = SupportPopoverButton;
Header.LoginButton = LoginButton;
Header.LoggedInActions = LoggedInActions;

export { Header };
