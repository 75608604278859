import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

export const Form = styled(Ui.Card)`
  position: absolute;
  top: ${({ theme }) => theme.spacing['70']};
  left: ${({ theme }) => theme.spacing['40']};
  right: ${({ theme }) => theme.spacing['40']};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing['40']};
  margin-inline: auto;
  padding: ${({ theme }) => theme.spacing['40']};
  max-width: 1220px;
`;

export const Headline = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1.125rem;
  font-weight: ${({ theme }) => theme.fontWeight.heading};
  color: ${({ theme }) => theme.color.brand};
`;

export const Icon = styled.span`
  grid-area: icon;
  display: inline-flex;
  align-items: center;
  padding-inline: ${({ theme }) =>
    `${theme.spacing['40']} ${theme.spacing['20']}`};
  border: 1px solid ${({ theme }) => theme.color.grey02};
  border-right: 0;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.sm};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.sm};
  height: 100%;
  fill: ${({ theme }) => theme.color.grey02};
`;

export const Placeholder = styled.span`
  grid-area: placeholder;
  display: block;
  padding-right: ${({ theme }) => theme.spacing['20']};
  border: 1px solid ${({ theme }) => theme.color.grey02};
  border-left: 0;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.sm};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.sm};
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  line-height: 3.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const AccessibleLabel = styled.span`
  grid-area: label;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  height: 100%;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.brand};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: bold;
`;

export const SearchButton = styled.button<{
  hasValue: boolean;
}>`
  display: grid;
  grid-template: 'icon placeholder' 3.5rem 'label label' 3.5rem / max-content minmax(
      0,
      1fr
    );
  align-items: center;
  gap: ${({ theme }) => theme.spacing['20']} 0;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  background: none;
  width: 100%;
  font: inherit;
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  text-align: start;
  text-transform: inherit;
  cursor: pointer;

  &:focus-visible {
    outline: ${({ theme }) => theme.spacing['5']} dashed
      ${({ theme }) => theme.color.secondary};
    outline-offset: ${({ theme }) => theme.spacing['10']};
  }

  ${Placeholder} {
    color: ${({ theme, hasValue }) =>
      hasValue ? theme.color.grey01 : theme.color.grey02};
  }
`;
