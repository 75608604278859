import { HostLegacy, LayoutLegacy } from '@silvertours/front-legacy-entities';
import { MediaLegacy } from '@silvertours/front-legacy-shared';
import { SearchForm } from '../SearchFormLegacy';
import {
  StageContentContainer,
  StageCaption,
} from '../StageLegacy/Stage.styles';

type Props = {
  color?: string;
  showPoweredBy?: boolean;
};

const setAffiliateConsentStyle = (customColor?: string) => (
  <style>
    {`
      .cmp-button.cmp-button-secondary, 
      .cmp-button.cmp-button-secondary:hover {
        background-color: #fff;
        color: ${customColor};
      }

      .cmp-button, 
      .cmp-button:hover {
        background-color: ${customColor};
        border-color: ${customColor};
      }
    
    `}
  </style>
);

const renderStageContainer = (color?: string, showPoweredBy?: boolean) => (
  <StageContentContainer isAffiliate>
    <StageCaption>
      {setAffiliateConsentStyle(color)}
      <SearchForm
        isAffiliate
        customColor={color}
        showPoweredBy={showPoweredBy}
      />
    </StageCaption>
  </StageContentContainer>
);

const AffiliateSearchForm = ({ color, showPoweredBy }: Props) => {
  const { formatImage } = HostLegacy.useHost();
  const image: MediaLegacy.ImageProps = {
    src: formatImage('/static/bm/global/files/images/XL-M_1400x470_200kb.jpg'),
    alt: 'car-rental',
    id: 'car-rental-stage',
  };

  if (showPoweredBy) {
    return renderStageContainer(color, showPoweredBy);
  }

  return (
    <LayoutLegacy.ImageSegment image={image}>
      {renderStageContainer(color, showPoweredBy)}
    </LayoutLegacy.ImageSegment>
  );
};

export { AffiliateSearchForm };
