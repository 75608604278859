import type { LogoProps } from '../types';
import { Svg } from '../ThirdPartyLogo.styles';

const viewBox = {
  both: '0 0 137 49',
  horizontal: '0 5.4 137 39',
  vertical: '14 0 110 49',
  none: '14 5.4 110 39',
};

const EuropcarLogo = ({ withPadding = 'both', ...props }: LogoProps) => (
  <Svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox[withPadding]}
    role="img"
    aria-hidden="true"
  >
    <title>Europcar</title>
    <path fill="#0e8102" d="M14 5.42h110v38.39H14z" />
    <path
      fill="#fd0"
      d="m73.19 32.2-.47 3.14h42.07v-.53c-7.12-.82-26.44-2.61-41.6-2.61Zm-4.76 0a395.66 395.66 0 0 0-45.01 2.66v.5h44.67-.2l.54-3.16Z"
    />
    <path
      fill="#fff"
      d="M35.1 25.57h-7.57l.67-3.48h6.9l.55-3.14h-6.77l.54-3.17h7.6l.5-3.12H26.55l-3.13 15.95h11.17l.52-3.04Zm5.08 3.34c2.66 0 2.95-1.26 2.95-1.03l-.16.73h3.29l2-11.62H45.1s-.63 4.2-1.04 6.14c-.41 1.94-1.09 2.67-2.56 2.67-1.45 0-1.24-1.1-1.11-1.85l1.21-6.96h-3.23s-1.01 6.7-1.37 8.5c-.39 1.9.52 3.42 3.18 3.42Zm12.93-7.18c.26-1.4.88-2.08 2-2.08h1.7l.68-2.64h-1.43c-1.37 0-2.2.96-2.2.65l.13-.67h-3.28l-2 11.62h3.14l1.26-6.88Zm35.99-5.3c-5.2 0-6.99 3.92-6.99 7.71 0 3.8 2.38 4.97 4.45 4.97 4.4 0 6.47-3.03 6.47-4.69h-3.08s-.54 2.02-2.8 2.02c-1.23 0-1.9-1.2-1.9-2.38s.67-5.14 3.49-5.14c2.12 0 1.83 2.53 1.83 2.53h3.21c-.02-.03.8-5.02-4.68-5.02Zm-20.41 4.6c0 3.48-1.97 8.08-6.96 8.08-3.42 0-4.79-2.13-4.79-5.05 0-2.95 1.5-7.55 7.1-7.55 3.15 0 4.65 1.54 4.65 4.52Zm-5.18-2.08c-2.66 0-3.41 3.79-3.41 4.6v.06c0 .85 0 2.84 2.04 2.86 2.1 0 3.29-2.86 3.29-4.8 0-1.96-.88-2.72-1.92-2.72Zm50.53-1.91c-1.37 0-2.2.96-2.2.65l.13-.68h-3.29l-1.99 11.62h3.13l1.27-6.87c.26-1.4.88-2.08 1.99-2.08h1.7l.68-2.64h-1.42Zm-39.7.72c.25-.27 1.04-1.08 3.17-1.08 2.82 0 3.41 2.3 3.44 4.24 0 1.93-1.14 8.22-5.59 8.22-1.65 0-2.5-.79-2.87-1.12-.13-.12-.2-.18-.2-.12-.3 1.21-1.2 7.44-1.2 7.44h-3.18l3.16-18.38h3.28s-.13.53-.13.84c0 .08.03.05.12-.04Zm-1.25 5.82c0 .73.1 2.58 1.68 2.58 1.58 0 3.08-2.38 3.05-4.37 0-1.18-.05-2.45-1.78-2.45-2.36 0-2.95 3.51-2.95 4.24Zm22.71-3.2s-.05-3.65 4.86-3.65c4.38 0 5.3 1.4 5.07 2.73-.05.37-.3 1.72-.57 3.09-.27 1.44-.55 2.89-.6 3.22-.07.65.37.42.37.42l-.57 2.42h-1.65c-.84 0-1.07-.31-1.21-.51-.07-.1-.12-.17-.22-.17-.07 0-.19.1-.36.24a4.8 4.8 0 0 1-3.2 1.17c-2.13 0-3.42-1.6-3.42-3.54s1.78-4.38 5.33-4.38h2.22c.62 0 1.5-2.58-1-2.58-2 0-2.13 1.54-2.13 1.54H95.8Zm2.95 3.65c-1.42.25-1.92 2.72-.08 2.72.96 0 2.87-.9 2.87-3.25 0 0-1.14.25-2.8.53Z"
    />
  </Svg>
);

// eslint-disable-next-line import/no-default-export
export default EuropcarLogo;
