import styled from '@emotion/styled';

const TrustWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  bottom: calc(100% - 30px);
  right: 0;
  max-width: 285px;
`;

const TrustElement = styled.div<{ isHighlighted?: boolean }>`
  padding: ${({ theme }) => theme.spacing[40]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ theme, isHighlighted }) =>
    isHighlighted ? '' : `1px solid ${theme.color.grey04}`};
  color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.color.white : theme.color.text};
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.color.brand : theme.color.white};
`;

export { TrustWrapper, TrustElement };
