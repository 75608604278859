import { BmOkContent, BodyContent, ContentSegments } from '../content';
import { ContentInterpolator } from '../content/ContentInterpolator';

export class Body implements BodyContent {
  constructor(
    private content: BmOkContent,
    private interpolator?: ContentInterpolator,
  ) {}

  get segments() {
    return this.content.body.segments;
  }

  get interpolatedSegments() {
    if (!this.interpolator) {
      return this.content.body.segments;
    }
    console.log('interpolating body segments: ', this.content.body.segments);
    return this.interpolator.interpolateBody(this.content.body)
      .segments as ContentSegments;
  }
}
