import { useState } from 'react';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { useMedia } from 'react-use';
import { analytics } from '@silvertours/front-entities';
import { http, Heading2, Ui, Theme } from '@silvertours/front-shared';
import { NewsletterRegistration as NewsletterRegistrationProps } from '@silvertours/common-landingpages-view';
import { TooltipBadge } from '@silvertours/front-legacy-entities';
import { NewsletterSignup } from './NewsletterSignup';
import { NewsletterSignupSuccess } from './NewsletterSignupSuccess';
import { useNewsletter } from './useNewsletter';
import {
  Card,
  NewsletterPictogramWrapper,
  NewsletterContainer,
  BadgeWrapper,
  NewsletterText,
  List,
  ListItem,
  StyledChecked,
  CardWrapper,
} from './NewsletterRegistration.styles';

const NewsletterPictogram = dynamic(() =>
  import('./NewsletterPictogram').then(module => module.NewsletterPictogram),
);

const Background = ({ children }: React.PropsWithChildren<{}>) => (
  <Ui.Wave type="scalable3" fullWidth backgroundStyle="mint">
    {children}
  </Ui.Wave>
);

const NewsletterRegistration = ({ type }: NewsletterRegistrationProps) => {
  const isLg = useMedia(`(min-width: ${Theme.getBreakpoint('lg')}px)`, false);
  const { formatApiUrl } = useNewsletter();
  const [success, setSuccess] = useState(false);
  const isIndexType = type === 'index';

  const t = useTranslations(
    'features.newsletterRegistrationLegacy.newsletterRegistration',
  );

  const trackRegistration = () => {
    if (type === 'index') {
      analytics.gtm.trackNewsletterRegistrationIndex();
    } else {
      analytics.gtm.trackNewsletterRegistration();
    }
  };

  const onSubmit = async (email: string) => {
    const sourceParam = {
      index: t('sourceParam.index'),
      landing: t('sourceParam.landing'),
    };

    try {
      await http.axiosInstance.post(formatApiUrl('v1/newsletter/subscribe'), {
        email,
        sourceParam: sourceParam[type],
      });

      setSuccess(true);
      trackRegistration();
    } catch (axiosError: unknown) {
      // TODO: error handling (if necessary?)
    }
  };

  const newsletterForm = () =>
    success ? (
      <NewsletterSignupSuccess showTitle={isIndexType} />
    ) : (
      <NewsletterSignup truncateLegalText={isIndexType} onSubmit={onSubmit} />
    );

  if (!isIndexType) {
    return newsletterForm();
  }

  return (
    <Background>
      <NewsletterContainer>
        <NewsletterText>
          <Ui.Badge type="light" text="Newsletter" />
          <Heading2>{t('title')}</Heading2>
          <List>
            <ListItem>
              <StyledChecked /> {t('usp.first')}
            </ListItem>
            <ListItem>
              <StyledChecked /> {t('usp.second')}
            </ListItem>
            <ListItem>
              <StyledChecked /> {t('usp.third')}
            </ListItem>
          </List>
        </NewsletterText>
        <CardWrapper>
          {isLg && (
            <NewsletterPictogramWrapper>
              <NewsletterPictogram />
            </NewsletterPictogramWrapper>
          )}
          <Card>
            <BadgeWrapper>
              <TooltipBadge
                badgeText={t('discount.badge')}
                tooltipTitle={t('discount.tooltip.title')}
                tooltipText={t.raw('discount.tooltip.text')}
                onHover={() => analytics.gtm.trackCancellationHover()}
              />
            </BadgeWrapper>
            {newsletterForm()}
          </Card>
        </CardWrapper>
      </NewsletterContainer>
    </Background>
  );
};

export { NewsletterRegistration };
