import { FormEvent, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import { Button, Ui } from '@silvertours/front-shared';

import {
  Form,
  LegalText,
  ToggleLink,
  StyledArrowDown,
  StyledArrowUp,
} from './NewsletterRegistration.styles';

type NewsletterSignupProps = {
  truncateLegalText?: boolean;
  onSubmit: (email: string) => void;
};

const NewsletterSignup = ({
  truncateLegalText = true,
  onSubmit,
}: NewsletterSignupProps) => {
  const [textVisible, setTextVisible] = useState(!truncateLegalText);
  const inputRef = useRef<HTMLInputElement>(null);
  const t = useTranslations(
    'features.newsletterRegistrationLegacy.newsletterRegistration',
  );

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (inputRef.current) {
      onSubmit(inputRef.current.value);
    }
  };

  const renderToggleLink = () => (
    <ToggleLink
      as="button"
      variant="text"
      onClick={() => setTextVisible(!textVisible)}
    >
      {textVisible ? (
        <>
          {t('showLess')} <StyledArrowUp />
        </>
      ) : (
        <>
          {t('showMore')} <StyledArrowDown />
        </>
      )}
    </ToggleLink>
  );

  return (
    <Form method="post" id="newsletter-form" onSubmit={handleSubmit}>
      <Ui.Input
        ref={inputRef}
        type="email"
        id="newsletter-email"
        name="email"
        label={t('label')}
        autoComplete="email"
        autoCorrect="off"
        autoCapitalize="none"
        required
      />
      <LegalText
        $textVisible={textVisible}
        $reduceMargin={truncateLegalText}
        dangerouslySetInnerHTML={{ __html: t.raw('legalText') }}
      />
      {truncateLegalText && renderToggleLink()}
      <Button
        type="submit"
        variant={truncateLegalText ? 'secondary' : 'primary'}
        fullWidth
      >
        {t('buttonText')}
      </Button>
    </Form>
  );
};

export { NewsletterSignup };
