import { PageAttributeType } from '@silvertours/back-domain-page';
import { PriceGraphContentModule } from '@silvertours/common-landingpages-view';
import {
  Graph,
  PlainContentModuleContainer,
  Carousel,
  CarouselRef,
  TabListContentBlock,
  TextContentBlock,
  useContentModule,
  analytics,
} from '@silvertours/front-entities';
import { useRef, useState } from 'react';
import { addDays } from 'date-fns/addDays';
import { isBefore } from 'date-fns/isBefore';
import { format } from 'date-fns/format';
import { getDay } from 'date-fns/getDay';
import { getMonth } from 'date-fns/getMonth';
import { getYear } from 'date-fns/getYear';
import { addWeeks } from 'date-fns/fp/addWeeks';
import { parseISO } from 'date-fns/parseISO';
import { setHours } from 'date-fns/setHours';
import { startOfDay } from 'date-fns/startOfDay';
import { startOfMonth } from 'date-fns/startOfMonth';
import { String, Ui } from '@silvertours/front-shared';
import { useSubmitInitialSearch } from '../../../SearchFormLegacy';
import { Spacer40, Spacer50 } from './PriceGraph.styles';

const DAY_OF_WEEK = 5;
const setDayOfWeek = (date: Date) => {
  const dayOfWeekToday = getDay(date);
  const daysUntil = (DAY_OF_WEEK - dayOfWeekToday + 7) % 7;
  return addDays(date, daysUntil);
};

const getFirstDayOfMonth = (dateString: string) => {
  let firstDay = setHours(setDayOfWeek(startOfMonth(parseISO(dateString))), 12);
  const today = setHours(startOfDay(new Date()), 12);
  if (
    isBefore(firstDay, today) &&
    getMonth(firstDay) === getMonth(today) &&
    getYear(firstDay) === getYear(today)
  ) {
    firstDay = setDayOfWeek(firstDay);
    if (getMonth(firstDay) > getMonth(today)) {
      firstDay = addDays(today, 1);
    }
  }
  return firstDay as Date;
};

const addOneWeek = addWeeks(1);
const formatDate = (date: Date) => format(date, String.isoDateTimeWithSeconds);

function getSearchRange(date: string) {
  const firstDayOfMonth = getFirstDayOfMonth(date);
  const nextDayOfMonth = addOneWeek(setHours(firstDayOfMonth, 9));
  return {
    start: formatDate(firstDayOfMonth),
    end: formatDate(nextDayOfMonth),
  };
}

export const PriceGraph = ({
  content,
  badge,
  heading,
  translations,
  searchQuery,
}: PriceGraphContentModule) => {
  const { getPriceGraph } = useContentModule();
  const { priceInfos } = getPriceGraph();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const carouselRef = useRef<CarouselRef>(null);
  const { makeSearchHandler } = useSubmitInitialSearch();
  const handlePriceGraphSearch = (
    cityCode: string,
    cityName: string,
    date: string,
  ) => {
    const handleSearch = makeSearchHandler({
      cityCode,
      cityName,
      query: cityName,
      displayName: cityName,
      type: PageAttributeType.City,
    });
    const range = getSearchRange(date);
    if (handleSearch) {
      analytics.gtm.trackPriceGraphSearch(range.start);
      handleSearch(searchQuery || cityName, {
        depDate: range.start,
        destDate: range.end,
      });
    }
  };

  return priceInfos && priceInfos.length > 0 ? (
    <PlainContentModuleContainer>
      <TextContentBlock
        content={{ content, heading, badge }}
        rules={{
          badge: { style: 'orange' },
          isContainerMultiColumn: false,
          textStyle: { style: Ui.RichTextStyle.Primary },
        }}
      />
      <Spacer50 />
      {priceInfos.length > 1 && (
        <TabListContentBlock
          behavior={{
            onTabSelected: (cityName: string) => {
              setCurrentTab(
                priceInfos.findIndex(info => info.cityName === cityName),
              );
              analytics.gtm.trackPriceGraphCityClick(cityName);
            },
          }}
          content={{
            ...content,
            modules: priceInfos.map((priceInfo, position) => ({
              name: priceInfo.cityName,
              moduleType: 'tab',
              position,
            })),
          }}
          rules={{
            textStyle: Ui.RichTextStyle.Primary,
          }}
        />
      )}
      <Spacer40 />
      <Carousel
        prevText={translations?.prevText ?? ''}
        nextText={translations?.nextText ?? ''}
        captionText={translations?.legalText}
        ref={carouselRef}
      >
        {priceInfos[currentTab] && (
          <Graph
            translations={translations}
            prices={priceInfos[currentTab].prices}
            cityCode={priceInfos[currentTab].cityCode}
            cityName={priceInfos[currentTab].cityName}
            triggerSearch={handlePriceGraphSearch}
            currentTab={currentTab}
            containerRef={carouselRef.current?.slideRef}
          />
        )}
      </Carousel>
    </PlainContentModuleContainer>
  ) : null;
};
