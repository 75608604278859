import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { Theme as ThemeType } from '@emotion/react';
import { InteractiveText, SecondaryText } from '@silvertours/front-shared';
import { OpeningTimesSchedule } from '../../OpeningTimesSchedule';

const getLayout = ({ theme }: { theme: ThemeType }) => css`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${theme.mq('lg')} {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    max-width: none;
  }
`;

const Anchor = styled.a`
  color: inherit;
  text-decoration: inherit;
`;

const PopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactInfo = styled.div`
  ${getLayout};
  padding-left: 1rem;
  padding-right: 1rem;
  row-gap: ${({ theme }) => theme.spacing[40]};

  > p:first-of-type {
    width: 100%;
  }
`;

const InteractivePhoneNumber = styled(InteractiveText)`
  margin-top: 1.5rem;
  text-align: center;
  font-size: 24px;

  ${({ theme }) => theme.mq('lg')} {
    margin-top: 1rem;
  }
`;

const StyledOpeningTimesSchedule = styled(OpeningTimesSchedule)`
  width: 100%;
  max-width: 222px;
  align-self: center;
  font-size: ${({ theme }) => theme.fontSize.sm.size};
`;

const VoucherInfo = styled.div`
  ${getLayout};
  background-color: #f5f5f5;
  margin-top: 2.5rem;
  padding: 1.5rem 1rem;
  text-align: center;

  ${({ theme }) => theme.mq('lg')} {
    margin-top: 2rem;
  }
`;

const VoucherExplanation = styled(SecondaryText)`
  margin-top: 1rem;
`;

export {
  Anchor,
  ContactInfo,
  InteractivePhoneNumber,
  PopoverWrapper,
  StyledOpeningTimesSchedule,
  VoucherExplanation,
  VoucherInfo,
};
