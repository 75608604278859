import dynamic from 'next/dynamic';
import CarTypes from '@engineering/icons/car-types';
import { Icon as IconData } from '@silvertours/common-landingpages-view';
import { Type } from '@silvertours/front-shared';

import { IconWrapper } from './Icon.styles';
import { IconRules, IconType } from './rule/Icon';

export type IconProps = {
  content?: IconData;
  rules?: IconRules;
};

const isCarPictogram = (
  iconKey: Type.CarTypeIcon | Type.Icon,
): iconKey is Type.CarTypeIcon => Object.keys(CarTypes).includes(iconKey);

export const Icon = ({ content, rules }: IconProps) => {
  if (!content) {
    return null;
  }

  const { color = 'current', indent = 0, size = 50 } = rules || {};
  const icon = content.key as IconType;

  let Icons = null;

  if (icon === 'Calendar') {
    Icons = dynamic(import('@engineering/icons/calendar'));
  } else if (icon === 'CityStation') {
    Icons = dynamic(import('@engineering/icons/city-station'));
  } else if (icon === 'Tipp') {
    Icons = dynamic(import('@engineering/icons/tipp'));
  } else if (icon === 'Location') {
    Icons = dynamic(import('@engineering/icons/location'));
  } else if (icon === 'Like') {
    Icons = dynamic(import('@engineering/icons/like'));
  } else if (icon === 'Checked') {
    Icons = dynamic(import('@engineering/icons/checked'));
  } else if (icon === 'Info') {
    Icons = dynamic(import('@engineering/icons/info'));
  } else if (icon === 'StationMarker') {
    Icons = dynamic(import('@engineering/icons/station-marker'));
  } else {
    Icons = dynamic(
      async () => {
        try {
          const module = await import('@engineering/icons');
          return module[icon as Type.Icon] as any;
        } catch {
          return null;
        }
      },
      { ssr: false },
    );
  }

  const IconComponent = isCarPictogram(icon) ? CarTypes[icon] : Icons;

  if (IconComponent === null) {
    return null;
  }

  return (
    <IconWrapper color={color} size={size} indent={indent}>
      <IconComponent />
    </IconWrapper>
  );
};
