import {
  Runtime,
  useContentModule,
  useSiteInfo,
} from '@silvertours/front-entities';
import { Theme } from '@silvertours/front-shared';
import { useMedia } from 'react-use';
import { TrustWrapper } from './TrustSeals.styles';
import { Trustpilot } from './TrustElements/Trustpilot';

type TrustSealsProps = {
  includeSearch?: boolean;
  isPlainTemplate?: boolean;
};

const TrustSeals = ({
  includeSearch = true,
  isPlainTemplate = false,
}: TrustSealsProps) => {
  const { featureToggles } = useSiteInfo();
  const { language } = Runtime.useLocale();
  const isGreaterThanSmall = useMedia(
    `(min-width: ${Theme.getBreakpoint('sm')}px)`,
    false,
  );
  const isGreaterThanLarge = useMedia(
    `(min-width: ${Theme.getBreakpoint('lg')}px)`,
    false,
  );

  const { getTrustpilotData } = useContentModule();
  const trustpilotProps = getTrustpilotData();

  if (
    (language !== 'de' && language !== 'fr') ||
    !featureToggles.trustElementOnStageImage ||
    !trustpilotProps ||
    !isGreaterThanSmall ||
    (!isPlainTemplate && !isGreaterThanLarge) ||
    (isPlainTemplate && includeSearch && !isGreaterThanLarge)
  ) {
    return null;
  }

  return (
    <TrustWrapper>
      <Trustpilot {...trustpilotProps} />
    </TrustWrapper>
  );
};

export { TrustSeals };
