import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThirdPartyLogo } from '@silvertours/front-entities';

const PrimaryTitle = styled.h1<{ isMinimal?: boolean }>`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.25;
  text-align: center;
  color: ${({ theme }) => theme.color.text};
  display: ${({ isMinimal }) => (isMinimal ? 'block' : 'none')};

  ${({ theme }) => theme.mq('sm')} {
    display: block;
    text-align: left;
  }

  ${({ theme }) => theme.mq('lg')} {
    font-size: 1.75rem;
    line-height: 1.4285;
  }
`;

const SupplierList = styled.ul<{ isStandAlone: boolean }>`
  grid-column: ${({ isStandAlone }) => (isStandAlone ? 'span 2' : '')};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  margin: 0 auto;
  padding: 0;
  min-height: 4rem;

  ${({ theme }) => theme.mq('sm')} {
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
    min-height: auto;
  }

  ${({ theme }) => theme.mq('lg')} {
    padding-top: ${({ isStandAlone }) => (isStandAlone ? '0' : '0.5rem')};
    padding-bottom: ${({ isStandAlone }) => (isStandAlone ? '0' : '0.5rem')};
    align-items: end;
    justify-content: ${({ isStandAlone }) => (isStandAlone ? 'center' : 'end')};
    gap: ${({ isStandAlone }) => (isStandAlone ? '2.5rem' : '1.5rem')};
  }
`;

const SupplierListItem = styled.li`
  display: inline-flex;
  align-items: center;
`;

const SupplierWrapper = styled.div<{
  includeSearch: boolean;
  isMinimal?: boolean;
}>`
  display: grid;
  gap: 1rem;
  padding: ${({ isMinimal }) => (isMinimal ? '2rem 0 2rem' : '3rem 0 2rem')};

  ${({ theme, includeSearch }) =>
    includeSearch &&
    css`
      ${theme.mq('lg')} {
        grid-template-columns: repeat(2, 0.5fr);
      }

      ${theme.mq('xl')} {
        grid-template-columns: 0.4fr 0.6fr;
      }
    `}
`;

const StyledThirdPartyLogo = styled(ThirdPartyLogo)`
  height: 1.5rem;
`;

export {
  PrimaryTitle,
  SupplierList,
  SupplierListItem,
  SupplierWrapper,
  StyledThirdPartyLogo,
};
