import { useTranslations } from 'next-intl';
import {
  ContentLayout,
  RentalCompanyPageContent,
} from '@silvertours/common-landingpages-view';
import {
  BenefitsLegacy,
  ContentArticle,
  OffersModule,
  SerpOffersModule,
  StageLegacy,
} from '@silvertours/front-features';

import { Body } from '../body';

const RentalCompanyContent = ({
  content: {
    article,
    introduction,
    links,
    location,
    attributes: { partners },
    offersByCategory,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<RentalCompanyPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);
  const offersTranslations = useTranslations('features.offers');
  const partner = (partners.length && partners[0].name) || '';

  let serpTitle = location.name
    ? offersTranslations('serp.title.partnerDestination')
        .replace('%LOCATION%', location.name)
        .replace('%PARTNER%', partner)
    : offersTranslations('serp.title.partner').replace('%PARTNER%', partner);

  let headline = location.name
    ? offersTranslations('headline.partnerDestination')
        .replace('%LOCATION%', location.name)
        .replace('%PARTNER%', partner)
    : offersTranslations('headline.partner').replace('%PARTNER%', partner);

  if (partner === '') {
    serpTitle = offersTranslations('serp.title.default');
    headline = offersTranslations('headline.default');
  }

  return (
    <Body
      title={primaryHeadline}
      stage={
        <StageLegacy.Stage content={stage.content} headline={primaryHeadline} />
      }
      content={({ containerModule }) => [
        containerModule(
          'usp',
          <BenefitsLegacy.Usp translationKey="rentalCompanyUsps" />,
        ),
        containerModule(
          'introduction-content',
          <ContentArticle
            modules={introduction?.segments ?? []}
            translations={translations}
          />,
          !!introduction?.segments,
        ),
        containerModule(
          'serpOffers',
          !!offersByCategory.content && (
            <SerpOffersModule
              content={offersByCategory.content}
              translations={{
                airConditioning: offersTranslations('car.airConditioning'),
                carTypes: t.raw('offers.car.type' as any),
                prevText: offersTranslations('prevText'),
                nextText: offersTranslations('nextText'),
                searchButtonText: offersTranslations('searchButtonText'),
                disclaimertext: offersTranslations('disclaimerSerpOffers'),
                pricePrefix: offersTranslations('pricePrefix'),
                priceSuffix: offersTranslations('priceSuffix'),
                serpTitle,
                transmissionAutomatic: offersTranslations(
                  'car.transmissionAutomatic',
                ),
                transmissionManual: offersTranslations(
                  'car.transmissionManual',
                ),
              }}
              searchQuery={
                partner !== ''
                  ? new URLSearchParams(
                      partners[0].partnerType === 'provider'
                        ? { provider: partner }
                        : { supplier: partner },
                    ).toString()
                  : ''
              }
            />
          ),
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        containerModule(
          'offers',
          !!offersByCategory.content && (
            <OffersModule
              content={offersByCategory.content}
              translations={{
                badgeText: offersTranslations('badge'),
                headline,
                disclaimer: offersTranslations('disclaimer'),
                disclaimerOverlayCTA: offersTranslations(
                  'disclaimerOverlayCTA',
                ),
                durationFrom: offersTranslations('durationFrom'),
                pricePrefix: offersTranslations('pricePrefix'),
                priceSuffix: offersTranslations('priceSuffix'),
                searchButtonText: offersTranslations('searchButtonText'),
                offerDetailsTitle: offersTranslations('offerDetailsTitle'),
                carTypes: t.raw('offers.car.type' as any),
              }}
            />
          ),
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        containerModule(
          'article',
          <ContentArticle
            modules={article.segments}
            translations={translations}
            searchQuery={
              partner !== ''
                ? new URLSearchParams(
                    partners[0].partnerType === 'provider'
                      ? { provider: partner }
                      : { supplier: partner },
                  ).toString()
                : ''
            }
          />,
        ),
        containerModule(
          'linkLists',
          <ContentArticle
            modules={links.content ?? []}
            translations={translations}
          />,
          !!links.content.length,
        ),
      ]}
      {...commonProps}
    />
  );
};

export { RentalCompanyContent };
